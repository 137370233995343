import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Toast from 'components/ui/Toast'
import { Footer } from './Footer'


export default function Main({
  which,
  children
}) {

  const [theme, setTheme] = useState((localStorage.getItem('theme') || 'light'))


  function toggleTheme() {
    if (theme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])



  return (
    <div data-bs-theme={theme}>
      <Navbar which={which} theme={{ toggleTheme, theme }} />

      <main id='main' className={`page-${which}`}>
          {children}
      </main>

      <Footer which={which} theme={{ toggleTheme, theme }} />
      <Toast/>

    </div>
  )
}
