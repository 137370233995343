import { 
    addUsersSelectedRole,
    clearUser,
    getUserRoles,
    removeUsersSelectedRole,
    setUserRoles, 
} from "features/users/store/users.action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEditUsers = () => {
    
    const dispatch = useDispatch();
    const roles = useSelector(state => state.users.roles)
    const selected = useSelector(state => state.users.selected);
    const selectedRolesLoading = useSelector(state => state.users.selectedRolesLoading)
    const selectedRoles = useSelector(state => state.users.selectedRoles)
    
    

    function addRole(role){
        dispatch(addUsersSelectedRole(role))
    }

    function removeRole(role){
        dispatch(removeUsersSelectedRole(role))
    }


    function onSaveRoles(user, roles){
        dispatch(setUserRoles(user, roles))
    }

    function clearSelected(){
        dispatch(clearUser())
    }
    
    useEffect(()=>{
        if(selected){
            dispatch(getUserRoles(selected.username))
        }
        return ()=>{
            dispatch(clearUser())
        }
    }, [selected, dispatch])


    return {
        roles,
        addRole,
        selected,
        removeRole,
        onSaveRoles,
        selectedRoles,
        clearSelected,
        selectedRolesLoading
    }
}