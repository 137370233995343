import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cronToEnglish, getJobHistory, getJobOverview, getJobs } from '../store/job.action';
import { useDebounce } from '@uidotdev/usehooks';

const initialOverview = {
  jobs: [],
  categories: []
}

export const useJobOverview = () => {
  
  const dispatch = useDispatch()
  const [overview, setOverview] = useState(initialOverview)
  const [overviewLoading, setOverviewLoading] = useState(false)
  const [overviewError, setOverviewError] = useState(false)

  useEffect(()=>{
        setOverviewLoading(true)
        dispatch(getJobOverview()).then( data => {
          setOverview(data);
        }).catch(error => {
          setOverviewError(true)
        }).finally(_=>{
          setOverviewLoading(false)
        });
     
  }, [dispatch, setOverview, setOverviewError, setOverviewLoading])

  return [overview, overviewLoading, overviewError];
}


export const useJobSearch = ({
  update = 5000
} = {}) => {
  const dispatch = useDispatch();
  
  const [job, setJob] = useState('');
  const [state, setState] = useState('');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(7);
  const [query, setQuery] = useState('');
  const [property, setProperty] = useState('data.category');
  const [isObjectId, setIsObjectId] = useState(false);
  const [page, setPage] = useState(1)

  const [jobsData, setJobsData] = useState({
    overview: {
      jobs: [],
      categories: []
    },
    jobs: []
  });

  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reRender, setRerender] = useState(0);



  const debouncedJobsData = useDebounce(jobsData);
  const debouncedIsLoading = useDebounce(isLoading, 500);



  const fetchJobs = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await dispatch(getJobs({
        job, state, skip, limit, query, property, isObjectId
      }));
      setJobsData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, job, state, skip, limit, query, property, isObjectId]); 


  useEffect(() => {
    fetchJobs();
  }, [fetchJobs, reRender]); // Only re-run the effect if fetchJobs changes


  useEffect(() => {
    const timeout = setTimeout(()=>{
      if(!error)
        setRerender(reRender+1);
    }, update);
    return ()=>{
      clearTimeout(timeout);
    }
  }, [update, reRender, error]);

  useEffect(()=>{
      setSkip((page - 1) * limit)
  }, [page, limit])
  

  return {
    search:  {
      job, setJob,
      state, setState,
      skip, setSkip,
      limit, setLimit,
      query, setQuery,
      property, setProperty,
      isObjectId, setIsObjectId,
      page, setPage
    },

    data: {
      ...debouncedJobsData,
      isLoading:debouncedIsLoading,
      error
    },
    
    setRerender
  };
};

export const useJobStateSetter = (list, setFunc, setRerender, action, setToMultiList) => {
   
  const dispatch = useDispatch()

  return useCallback(async () => {
      const result = await dispatch( action( list.map(j => j._id) ));
      if (result) {
          setFunc([]);
          setToMultiList([])
          setRerender(prev => prev + 1);
      }
  }, [list, setFunc, setRerender, dispatch, action, setToMultiList]);
};


export const useViewJobHistory = (job) => {

  const dispatch = useDispatch()
  const [history, setHistory] = useState([])
  const [historyLoading, setHistoryLoading] = useState(false)
  const [historyError, setHistoryError] = useState(false)

  useEffect(()=>{
      if(job){
        setHistoryLoading(true)
        dispatch(getJobHistory(job._id)).then( data => {
          setHistory(data);
        }).catch(error => {
          setHistoryError(true)
        }).finally(_=>{
          setHistoryLoading(false)
        });
      }else{
        setHistory([])
      }
  }, [job, dispatch, setHistory, setHistoryError, setHistoryLoading])

  return [history, historyLoading, historyError];

}


export const useCronToEnglish = (expression) => {

  const dispatch = useDispatch()
  const debouncedExpression = useDebounce(expression, 500);
  const [cronResult, setCronResult] = useState({result: false});

  useEffect(()=>{
    if(debouncedExpression){
      dispatch(cronToEnglish(debouncedExpression))
      .then(data => {
        if(data && data.result){
          setCronResult(data)
        }else{
          setCronResult({result: false})
        }
      })
      .catch(()=>{
        setCronResult({result: false})
      })
    }else{
      setCronResult({result: false})
    }
  }, [debouncedExpression, dispatch])

  return cronResult;

}