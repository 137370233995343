import { GbcButton } from 'components/form'
import React, { useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { changePassword } from '../store/profile.action';


export function ProfileSecurity() {

  const dispatch = useDispatch()
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');




  return (
    <Container>
      <Card>
        <Card.Body>
          <h4 className='mb-5'>Change Password</h4>
          <Form>
            <Form.Group as={Row}>
              <Form.Label column md="3" lg="2">Current Password:</Form.Label>
              <Col md="9" lg="10">
                <Form.Control type='password' onInput={(e)=>setCurrentPassword(e.target.value)}  placeholder='Enter your current password' value={currentPassword} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mt-4'>
              <Form.Label column md="3" lg="2">New Password:</Form.Label>
              <Col md="9" lg="10">
                <Form.Control type='password' onInput={(e)=>setNewPassword(e.target.value)} placeholder='Enter your new password' value={newPassword}/>
              </Col>
            </Form.Group>
          </Form>
          <GbcButton onClick={()=>dispatch(changePassword(newPassword, currentPassword))} className='float-end mt-4'>
            Save
          </GbcButton>
        </Card.Body>
      </Card>
    </Container>
  )
}
