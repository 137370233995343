import { useDispatch, useSelector } from 'react-redux'
import Main from 'layouts/Main'
import { Container } from 'react-bootstrap'
import { UsersSearch, UsersEdit } from 'features/users'
import { makeToast } from 'actions/toast.actions'





export default function Users() {

    const users = useSelector(state => state.users)
    const dispatch = useDispatch()








    return (
        <Main which="users">
            <Container>
                {
                    users.selected ?
                        <>
                            <UsersEdit />
                        </>
                        :
                        <>
                            <UsersSearch />
                        </>
                }
            </Container>
        </Main>
    )
}
