import { Modal, Button, ListGroup } from "react-bootstrap";
import { useState } from "react";
import { JobListItem } from "./JobList";


export function ConfirmJobModal({
    title, 
    jobs = [],
    action, 
    setJobs,
    handleClose = () => setJobs([]),
    onClick,
    setView
}){

    return(
        <Modal
            show = {action && jobs.length}
            onHide = {handleClose}
            backdrop = "static"
            size="xl" 
        >
            <Modal.Header closeButton>
                {title && <Modal.Title>{title}</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {jobs.length && jobs.map(job => <JobListItem key={job._id} controls={{setView}} job={job}/>)}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary" onClick={onClick}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
        
    

}