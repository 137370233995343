import { TOAST_REMOVE, TOAST_ENTERED, TOAST_HIDE, TOAST_SHOW, TOAST_ENTER, TOAST_MAKE } from "reducers/toast.reducer";


export const makeToast = (title, message, {
    type = '',
    duration = 3000,
    icon = 'bi-info-circle',
    enterDuration = 500,
    exitDuration = 500
} = {}) => ({
    type: TOAST_MAKE,
    payload: { id: Date.now().toString(), title, message, type, duration, icon, enterDuration, exitDuration }
});

export const removeToast = (id) => ({
    type: TOAST_REMOVE,
    payload: id
});

export const toastEnter = (id) => ({
    type: TOAST_ENTER,
    payload: id
});

export const toastEntered = (id) => ({
    type: TOAST_ENTERED,
    payload: id
});


export const hideToast = (id) => (dispatch) => {
    dispatch({
        type: TOAST_HIDE,
        payload: id
    });
};


