import React from 'react'
import { Badge, Form } from 'react-bootstrap'


export function UsersRoles({
    roles,
    onRoleAdd = _ => { },
    selectedRoles,
    onRoleRemove = _ => { },
}) {

    return (

        <Form.Group className="users-search-form my-3 d-flex flex-wrap justify-content-center" controlId="search">
            {roles.map(role => {
                const selected = selectedRoles.includes(role);
                return <Badge
                    pill
                    key={role}
                    onClick={() => { selected ? onRoleRemove(role) : onRoleAdd(role) }}
                    className={`me-2 my-1 role-search${selected ? '' : ' muted'}`} >
                    {role}
                </Badge>
            }
            )}
        </Form.Group>
    )
}