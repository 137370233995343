import React from 'react'
import Main from '../layouts/Main'
import { LoginForm } from '../features/authentication'
import { Card, Container } from 'react-bootstrap'






export default function Login() {
  return (
    <Main which="login">
      <div>
        <div className='login-brand-container'>
          <h1 className='mb-4'>{process.env['REACT_APP_EMOJI']}</h1>
          <h1>{process.env['REACT_APP_NAME']}</h1>
          <p>Smart Solutions, Infinite Possibilities. 🚀</p>
        </div>
        <Container>

          <Card className='login-card'>
            <Card.Header className='bg-primary text-light' >Login</Card.Header>
            <Card.Body>
              <LoginForm />
            </Card.Body>
          </Card>

        </Container>

      </div>
    </Main >
  )
}
