import { Card, FormControl } from "react-bootstrap";
import { UsersRoles } from "./UsersRoles";
import { useEditUsers } from "../hooks/edit.hook";
import { Load } from "components/ui";
import { GbcButton } from "components/form";

export function UsersEdit() {

    const {
        roles,
        addRole,
        selected,
        removeRole,
        onSaveRoles,
        selectedRoles,
        clearSelected,
        selectedRolesLoading
    } = useEditUsers()


    return (
        <>
            <div className="animate__animated animate__fadeIn">
                <Card className="mt-4">
                    <Card.Header bg='primary' >Edit User</Card.Header>
                    <Card.Body>
                        <h2 className="text-center">Username: <code>{selected.username}</code></h2>
                        {
                            selectedRolesLoading
                                ?
                                <Load width={200} />
                                :
                                <>
                                    <Card className="mb-4">
                                        <Card.Body>
                                            <h5>Roles</h5>
                                            <UsersRoles
                                                onRoleAdd={(authority) => addRole(authority)}
                                                onRoleRemove={(authority) => removeRole(authority)}
                                                roles={roles}
                                                selectedRoles={selectedRoles} />
                                            <GbcButton onClick={() => onSaveRoles(selected, selectedRoles)} className='float-end' variant='primary'>
                                                Save
                                            </GbcButton>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <h5>Raw Data</h5>
                                            <FormControl style={{ resize: 'none' }} rows={8} readOnly as='textarea' value={JSON.stringify(selected, null, 2)} />


                                        </Card.Body>
                                    </Card>
                                </>
                        }

                        <GbcButton chevron="left" onClick={() => clearSelected()} className='mt-3' variant='outline-primary'>
                            Close
                        </GbcButton>

                    </Card.Body>

                </Card>

            </div>
        </>
    )
}