
import { useHover, useClickAway } from '@uidotdev/usehooks';
import { Authorized, LoginForm, logout } from 'features/authentication';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import LogoWhite from 'assets/images/idi-site-icon-white.png'
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import BSNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


function AppNavLink({
  path,
  which,
  name = path,
  children,
  as = Nav.Link,
  ...rest
}) {
  const location = useLocation()
  const navigate = useNavigate()
  return React.createElement(as, {
    active: (which === name),
    onClick: () => navigate('/' + path, { state: { from: location.pathname } }),
    ...rest
  }, children)
}



function Navbar({
  expand = 'md',
  which,
  theme
}) {

  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const handleCloseOffCanvas = () => setShowOffCanvas(false);
  const handleShowOffCanvas = () => setShowOffCanvas(true);

  //Controls Login Dropdown for login form (un-authorized)
  const [showLoginForm, setShowLoginForm] = useState(false)

  //Hover tracking for login button
  const [loginBtnRef, hoveringLoginBtn] = useHover(false)

  //If Login is hovered show form, but don't close
  useEffect(() => {
    if (hoveringLoginBtn) setShowLoginForm(true)
  }, [hoveringLoginBtn])

  //Tracks if user clicks out of login form, If so close dropdown
  const loginFormRef = useClickAway(() => {
    setShowLoginForm(false)
  })


  return (
    <div className='nav-wrapper'>
      <BSNavbar id='gbc-nav' bg='primary' key={expand} expand={expand}>
        <Container fluid>
          <BSNavbar.Brand  href={'/'}><img width='40' src={LogoWhite} alt="IDI Logo in white" /><i className='navbar-brand-text'>Intellisoft Development</i></BSNavbar.Brand>

          <BSNavbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShowOffCanvas} />
          <BSNavbar.Offcanvas
            data-bs-theme={theme.theme}
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={showOffCanvas}
            onHide={handleCloseOffCanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Options <i className="bi bi-gear"></i>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="nav-btns justify-content-center w-100 start-0 font-emphasized">

                {/* Put you Page Links Here */}

                <AppNavLink className='home-btn' path="" name="home" which={which}>Home</AppNavLink>
                <AppNavLink path="contact" name="contact" which={which}>Contact</AppNavLink>



              </Nav>
              <Nav className="nav-btns-end justify-content-end ms-auto">
                <Authorized replace={
                  <NavDropdown
                    ref={loginBtnRef}
                    show={showLoginForm || showOffCanvas}
                    className='drop-right'
                    active={which === 'login'}
                    title={<span onClick={() => { navigate('/login') }}>Login</span>}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <Container ref={loginFormRef}>
                      <LoginForm></LoginForm>
                    </Container>

                  </NavDropdown>
                }>
                  <NavDropdown
                    className='drop-right'
                    title={auth.user?.[process.env['REACT_APP_COL_FULLNAME']]}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >

                    <AppNavLink as={NavDropdown.Item} name="profile" path="profile" which={which} >
                      <i className="bi bi-person-circle"></i> Profile
                    </AppNavLink>

                    <Authorized roles={['ROLE_DEVELOPER']}>
                      <AppNavLink as={NavDropdown.Item} name="jobs" path="jobs" which={which} >
                        <i className="bi bi-alarm-fill"></i> Jobs
                      </AppNavLink>
                    </Authorized>

                    <Authorized roles={['ROLE_DEVELOPER']}>
                      <AppNavLink as={NavDropdown.Item} name="users" path="users" which={which} >
                        <i className="bi bi-people-fill"></i> User Settings
                      </AppNavLink>
                    </Authorized>

                    <NavDropdown.Divider />

                    <NavDropdown.Item href="#logout" onClick={() => dispatch(logout())}>
                      Logout
                    </NavDropdown.Item>

                  </NavDropdown>
                </Authorized>
                {
                  !showOffCanvas && <>
                    <div className="vr d-none d-lg-flex h-100 mx-lg-1 text-white"></div>
                    <Nav.Link onClick={theme.toggleTheme}>
                      {
                        theme.theme === 'dark' ?
                          <i className="bi bi-brightness-high-fill"></i>
                          :
                          <i className="bi bi-moon-stars"></i>
                      }
                    </Nav.Link>
                  </>
                }


              </Nav>

              {
                showOffCanvas && <Container fluid className='nav-theme-container-offcanvas'>
                  <Form.Check
                    checked={theme.theme === 'dark'}
                    onClick={theme.toggleTheme}
                    type="switch"
                  /> Dark Mode
                </Container>
              }
            </Offcanvas.Body>
          </BSNavbar.Offcanvas>
        </Container>
      </BSNavbar>

    </div>
  );
}

export default Navbar;