//Input Data
export const USERS_SEARCH_TERM = 'USERS_SEARCH_TERM';
export const USERS_SEARCH_PAGE = 'USERS_SEARCH_PAGE';
export const USERS_SEARCH_ADD_ROLE = 'USERS_SEARCH_ADD_ROLE';
export const USERS_SEARCH_REMOVE_ROLE = 'USERS_SEARCH_REMOVE_ROLE';

//Response
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_LOADED = 'USERS_LOADED';
export const USERS_ROLES_LOADING = 'USERS_ROLES_LOADING';
export const USERS_ROLES_LOADED = 'USERS_ROLES_LOADED';
export const USERS_ERROR = 'USERS_ERROR';

export const USERS_SELECTED_ROLE_LOADING = 'USERS_SELECTED_ROLE_LOADING';
export const USERS_SELECTED_ROLE_LOADED = 'USERS_SELECTED_ROLE_LOADED';
export const USERS_SELECTED_ROLE_ADD = 'USERS_SELECTED_ROLE_ADD';
export const USERS_SELECTED_ROLE_REMOVE = 'USERS_SELECTED_ROLE_REMOVE';

export const SELECT_USER = 'SELECT_USER';


const initial = {

    //Input Data
    searchTerm: '',
    searchRoles: [],
    page: 1,

    //Response Data
    isLoading: false,
    users: [],
    count: 0,

    pagination: {},

    selected: null,
    selectedRolesLoading: true,
    selectedRoles: [],

    roles: [],
    rolesIsLoading: false,


}


export function usersReducer(state = initial, action) {
    switch (action.type) {

        /** EDIT USERS */
        case USERS_SELECTED_ROLE_ADD:
            return {
                ...state,
                selectedRoles: [...state.selectedRoles, action.payload]
            }

        case USERS_SELECTED_ROLE_REMOVE:
            
            return {
                ...state,
                selectedRoles:  state.selectedRoles.filter(item => item !== action.payload)
            }
        case USERS_SELECTED_ROLE_LOADING:
            return {
                ...state,
                selectedRolesLoading: true
            }
        case USERS_SELECTED_ROLE_LOADED:
            return {
                ...state,
                selectedRolesLoading: false,
                selectedRoles: action.payload.rows
            }

        /** SEARCH USERS */
        case USERS_SEARCH_PAGE:
            return {
                ...state,
                page: action.payload
            }
        case USERS_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            }
        case USERS_SEARCH_ADD_ROLE:
            return {
                ...state,
                searchRoles: [...state.searchRoles, action.payload]
            }
        case USERS_SEARCH_REMOVE_ROLE:
            return {
                ...state,
                searchRoles: state.searchRoles.filter(item => item !== action.payload)
            }
        case SELECT_USER:
            return {
                ...state,
                selected: action.payload,
                selectedRolesLoading: true,
            }

        case USERS_LOADING:
            return {
                ...state,
                isLoading: true,
                users: [],
                count: 0,
                pagination: {}
            }
        case USERS_LOADED:
            return {
                ...state,
                isLoading: false,
                users: action.payload.rows,
                count: action.payload.count,
                pagination: action.payload.pagination
            }
        case USERS_ROLES_LOADING:
            return {
                ...state,
                rolesIsLoading: true,
            }
        case USERS_ROLES_LOADED:
            return {
                ...state,
                rolesIsLoading: false,
                roles: action.payload.rows,
            }

        default:
            return state;
    }

}