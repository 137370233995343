import { Load } from "components/ui";
import Main from "layouts/Main";



export function Loading(){
    return (
        <Main which='loading'>
            <Load className="h-100" />
        </Main>
    )
}