import { authRequest } from "features/authentication";
import {  SELECT_USER, USERS_ERROR, USERS_LOADED, USERS_LOADING, USERS_ROLES_LOADED, USERS_ROLES_LOADING, USERS_SEARCH_ADD_ROLE, USERS_SEARCH_PAGE, USERS_SEARCH_REMOVE_ROLE, USERS_SEARCH_TERM, USERS_SELECTED_ROLE_ADD, USERS_SELECTED_ROLE_LOADED, USERS_SELECTED_ROLE_LOADING, USERS_SELECTED_ROLE_REMOVE } from "./users.reducer";
import { makeToast } from "actions/toast.actions";

/** Types */
export const getUsers = (username, authority, page, limit = 2) => async (dispatch, getState) => {
    dispatch({ type: USERS_LOADING });
    try {
        
        const users = await dispatch(authRequest('/users',  {
            method:'POST',
            body: { 
                search:{
                    username: username + '%',
                    roles: {
                        authority
                    }
                },
                limit,
                page,
            },
            errorToast: true
        }));
        dispatch({ type: USERS_LOADED, payload: users });
    } catch (err) {
        dispatch({ type: USERS_ERROR });
    }
};


export const getRoles = (username = '%') => async (dispatch, getState) => {
    dispatch({ type: USERS_ROLES_LOADING });
    try {
        const roles = await dispatch(authRequest('/users/roles',  {
            method:'POST',
            body: { 
                search:{
                    user:{
                        username
                    }
                },
            },
        }));
        
        dispatch({ type: USERS_ROLES_LOADED, payload: roles });
    } catch (err) {
        dispatch({ type: USERS_ERROR });
    }
};

export const getUserRoles = (username) => async (dispatch, getState) => {
    dispatch({ type: USERS_SELECTED_ROLE_LOADING });
    try {
        const result = await dispatch(authRequest('/users/roles',  {
            method:'POST',
            body: { 
                search:{
                    user:{
                        username
                    }
                },
            },
        }));
       
        dispatch({ type: USERS_SELECTED_ROLE_LOADED, payload: result });
    } catch (err) {
        dispatch({ type: USERS_ERROR });
    }
};

export const setUserRoles = (user, roles) => async (dispatch, getState) => {
    try {
        const result = await dispatch(authRequest(`/users/${user.id}/role`,  {
            method:'POST',
            body:{
                roles
            }
        }));
        if(result){
            dispatch(makeToast('Success', 'Roles saved successfully', {type:'success', icon:'bi-check-circle-fill'}))
        }
        
        //dispatch(getUserRoles(user.username));
    } catch (err) {
        dispatch({ type: USERS_ERROR });
    }
};


export const addUsersSelectedRole = role => (dispatch) => {
    dispatch({type: USERS_SELECTED_ROLE_ADD, payload:role})
}

export const removeUsersSelectedRole = role => (dispatch) => {
    dispatch({type: USERS_SELECTED_ROLE_REMOVE, payload:role})
}

export const isLoading = _ => (dispatch) => {
    dispatch({type: USERS_LOADING})
}

export const setUsersSearchTerm = search => (dispatch) => {
    dispatch({type: USERS_SEARCH_TERM, payload:search})
}
export const setUsersSearchPage = page => (dispatch) => {
    dispatch({type: USERS_SEARCH_PAGE, payload:page})
}

export const addUsersSearchRole = role => (dispatch) => {
    dispatch({type: USERS_SEARCH_ADD_ROLE, payload:role})
}

export const removeUsersSearchRole = role => (dispatch) => {
    dispatch({type: USERS_SEARCH_REMOVE_ROLE, payload:role})
}

export const selectUser = user => (dispatch, getState) => {
    dispatch({ type:SELECT_USER, payload:user })
}

export const clearUser = _ => (dispatch, getState) => {
    dispatch({ type:SELECT_USER, payload:null })
}

