import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { login } from "../store/auth.action"

export const useLogin = () => {
    
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const error = useSelector(state => state.error)
    const navigation = useNavigate()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const location = useLocation()

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (location?.state?.from?.pathname) {
                navigation(location.state.from.pathname)
            } else {
                navigation('/')
            }
        }
    }, [auth.isAuthenticated]);


    const setInput = (i, setter) => {
        setter(i.target.value);
    }


    const onLogin = e => {
        e.preventDefault()
        dispatch(login({ username, password }));
    }

    return {
        username,
        setUsername,
        password,
        setPassword,
        onLogin,
        setInput
    }
}