import { useHover } from "@uidotdev/usehooks"
import { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import css from 'App.scss'
import { useNavigate } from "react-router-dom"


const totalRocketTime = (
    +css['home-timing-jumbleRocket']
    + +css['home-timing-shootOffRocket']
    + +css['home-timing-returnDelayRocket']
    + +css['home-timing-returnRocket']
  ) * 1000 // Multiplying by 1000 for milliseconds


export const HeroStrip = ({}) => {
    const [rocketRef, rocketHovered] = useHover()
    const [rocketLiftOff, setRocketLiftOff] = useState(false);

    const navigate = useNavigate()
  
    useEffect(()=>{
      if(rocketHovered){
        setRocketLiftOff(true)
        const timeOut = setTimeout(()=>{
          setRocketLiftOff(false)
        }, totalRocketTime)
        //return ()=> clearTimeout(timeOut)
      }
    },[rocketHovered, setRocketLiftOff])
    return (
        <Container className='pt-4'>
        <h1 className='cursor-default animate__animated animate__fadeIn hero-text'>
          Smart Solutions, Infinite Possibilities. <br/> <b>Intellisoft Development. <span className={`rocket ${rocketLiftOff ? 'rocket-animate' : ''}`} ref={rocketRef}>🚀</span></b>
        </h1>
        <h4 onClick={()=>navigate('/contact')} className='get-in-touch mt-5 mb-3' >Get In Touch <i className="bi bi-chevron-right"></i></h4>
      </Container>
    )
}