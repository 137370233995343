import logo from './logo.svg';
import './App.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from './features/authentication'
import {
  Routes,
  Route, 
  useNavigate,
  BrowserRouter,
} from "react-router-dom";
import { PrivateRoute } from './features/authentication';

import Users from './pages/Users';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from 'pages/Profile';
import Jobs from 'pages/Jobs';
import Contact from 'pages/Contact';



function App() {

  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  //Run to initially get user
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  
  return (
    <Routes>
    <Route path='/' element={<Home/>}></Route>
    <Route path='/contact' element={<Contact/>}></Route>
    <Route path='/login' element={<Login/>}></Route>
    <Route path='/users' element={
      <PrivateRoute roles={{any:['ROLE_DEVELOPER']}}>
        <Users/>
      </PrivateRoute>
    }></Route>
    <Route path='/profile' element={
      <PrivateRoute>
        <Profile/>
      </PrivateRoute>
    }></Route>
    <Route path='/jobs' element={
      <PrivateRoute roles={{any:['ROLE_DEVELOPER']}}>
        <Jobs/>
      </PrivateRoute>
    }></Route>
  </Routes>
  );
}

export default App;
