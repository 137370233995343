import React from 'react'
import { Badge, Col, Container, ListGroupItem, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { selectUser } from '../store/users.action'

export function UserListItem({ user }) {
    
    const dispatch = useDispatch()

    return (
        <ListGroupItem key={user.id} action onClick={_=>dispatch(selectUser(user))}>
            <Container>
                <Row>
                    <Col>
                        <div className=" me-auto">
                            <div className="fw-bold">{user[process.env['REACT_APP_COL_FULLNAME']]}</div>
                            <span>Username:</span> <span className='text-muted'>{user[process.env['REACT_APP_COL_USERNAME']]}</span>
                        </div>
                        
                    </Col>
                    <Col>
                        <div className='d-flex flex-row-reverse'>
                        {
                            user.roles.map(role => <Badge key={role} pill className='ms-1'>
                                {role}
                            </Badge>)
                        }
                        </div>
                    </Col>
                </Row>

            </Container>

        </ListGroupItem>
    )
}
