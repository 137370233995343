import { makeToast } from "actions/toast.actions";
import api from "../../../services/api.service";

import {
    AUTH_USER_LOADED,
    AUTH_USER_LOADING,
    AUTH_ERROR,
    AUTH_LOGIN_FAIL,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_SUCCESS,
} from "./auth.reducer";


 export const getUser = _ => async (dispatch, getState) => {
    dispatch({ type: AUTH_USER_LOADING });
    try {
        const data = await dispatch(authRequest('/auth/login'));
        dispatch({ type: AUTH_USER_LOADED, payload: data });
    } catch (err) {
        dispatch({ type: AUTH_ERROR });
    }
};

export const authRequest = (url, options = {}, retryCount = 1) => {
    return async (dispatch, getState) => {
       
        const auth = getState().auth
        
        if(!options.headers){
            options.headers = {
                "Content-Type": "application/json"
            }
        }

        if(!options.headers.authorization){
            options.headers.authorization = `Bearer ${auth.token}`;
        }

        try {
            return await api.request(url, options);
        } catch (err) {
            
            if ((err?.status === 401 || err?.status === 403 )&& retryCount > 0) {
                // Refresh the token
                const refresh = auth.refresh;
                
                try {
                    const data = await api.request('/auth/refresh', {
                        method: 'POST',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ refresh })
                    });
                    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: data });
                    
                    // Retry the request with the new token
                    options.headers.authorization = `Bearer ${data.token}`;



                    return dispatch(authRequest(url, options, retryCount - 1));
                
                } catch (err) {
                    dispatch({ type: AUTH_ERROR });
                }
            } else {
                if(err.message && options.errorToast){
                    dispatch(makeToast('Oh no!', err.message, {type:'danger', duration:6000, icon:'bi-exclamation-octagon-fill'}))
                }
                throw err;
            }
        }
    };
}

export const logout = () => async (dispatch, getState) => {
    const refresh = getState().auth.refresh;
    try {
        await api.request('auth/logout', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ refresh, global: true })
        });
    } catch (err) {
       
    } finally {
        dispatch({ type: AUTH_LOGOUT_SUCCESS });
    }
};

export const login = ({ username, password }) => async (dispatch) => {
    dispatch({ type: AUTH_USER_LOADING });
    try {
        const data = await api.request('/auth/login', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password })
        });
        dispatch({ type: AUTH_LOGIN_SUCCESS, payload: data });
    } catch (err) {
        if(err?.status === 401){
            dispatch({ type: AUTH_LOGIN_FAIL });
        }
    }
};
