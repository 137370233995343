import React from 'react';
import BsPagination from 'react-bootstrap/Pagination';

export const Pagination = ({
    pagination = {},
    setPage,
    className = '',
    maxPages = 10
}) => {

    const {
      totalPages = 1,
      previous = false,
      next = false,
      page = 1
    } = pagination;

    if (!pagination) return <></>;

    let items = [];
    let startPage, endPage;

    if (maxPages && totalPages > maxPages) {
        const halfMax = Math.floor(maxPages / 2);

        if (page <= halfMax) {
            // Current page is closer to the start
            startPage = 1;
            endPage = maxPages;
        } else if (page >= totalPages - halfMax) {
            // Current page is closer to the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // Current page is somewhere in the middle
            startPage = page - halfMax;
            endPage = page + halfMax;
        }

        // Ellipsis at the start
        if (startPage > 1) {
            items.push(
                <BsPagination.Item onClick={() => setPage(1)} key="ellipsis-start">
                    ...
                </BsPagination.Item>
            );
        }

        // Pagination numbers
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <BsPagination.Item onClick={() => setPage(number)} key={number} active={number === page}>
                    {number}
                </BsPagination.Item>,
            );
        }

        // Ellipsis at the end
        if (endPage < totalPages) {
            items.push(
                <BsPagination.Item onClick={() => setPage(totalPages)} key="ellipsis-end">
                    ...
                </BsPagination.Item>
            );
        }
    } else {
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <BsPagination.Item onClick={() => setPage(number)} key={number} active={number === page}>
                    {number}
                </BsPagination.Item>,
            );
        }
    }

    return (
        <BsPagination className={'justify-content-center gap-1 gbc-pagination ' + className}>
            {previous && <BsPagination.Prev className='end' onClick={() => setPage(previous)} />}
            {items}
            {next && <BsPagination.Next className='end' onClick={() => setPage(next)} />}
        </BsPagination>
    );
}
