import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Autocomplete } from "components/form"
import { Dial } from "components/ui"
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import * as icons from 'simple-icons';
import experienceList from 'assets/data/experience'
import { useNavigate } from "react-router-dom";



export const ExperienceStrip = ({ }) => {
    const skills = useRef(experienceList.sort(_ => Math.random() - 0.5))
    const [hasIntersected, setHasIntersected] = useState(false)
    const navigate = useNavigate()

    const [ref, entry] = useIntersectionObserver({
        threshold: [.3, 1], //30% of the content is show load or 100% (page reloads already scrolled down)
        root: null,
        rootMargin: "0px",
    });

    useEffect(() => {
        if (entry?.isIntersecting) {
            setHasIntersected(true)
        }
    }, [entry])


    return (
        <Container className={`experience-strip`}>
            <h2 className='text-center service-text'>Ample Experience</h2>
            <p className="text-center text-muted mt-3 mb-4">
                Our team is adept in a vast spectrum of technologies and methodologies, constantly enhancing our skill set to address the dynamic demands of the tech industry. 
                Learn more about our specialized knowledge by <Button className="p-0 m-0" variant="link" onClick={()=>navigate('/contact')}>getting in touch with us.</Button>
            </p>
            <div  className="marquee-position mt-4">
                <Marquee
                    className="experience-marquee"
                    speed={30}
                > 
                    {skills.current.map((s, i) => {
                        const primary = (i + 2) % 2;
                        const logo = `si${s.logo.charAt(0).toUpperCase() + s.logo.slice(1)}`
                        
                        return (
                            <Badge className="me-3 threed-badge" bg={primary ? 'primary' : 'secondary'}>
                                <svg
                                    viewBox={s?.viewBox || "0 0 24 24"}
                                    width='30px'
                                    // height={size}
                                    fill={primary ? 'var(--bs-light)' : 'var(--bs-dark)'}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d={icons[logo]?.path || s?.path} />
                                </svg>
                                <span className={`align-middle ms-2 h5 text-${primary ? 'light' : 'dark'}`}>{s.message}</span>
                            </Badge>
                        )

                    })}
                </Marquee>
                <div className="marquee-fade"></div>
            </div>
        </Container>
    )
}
