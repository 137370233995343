import { ContactStrip, TitleStrip } from "features/contact";
import Main from "layouts/Main";



export default function Contact(){
    return (
        <Main which='contact'>
           <div className="title-strip py-3">
                <TitleStrip />
           </div>
           <div className="contact-strip">
                <ContactStrip />
           </div>
        </Main>
    )
}