

const path = path => {
    let envPath = [process.env.REACT_APP_API_BASE]
    if (process.env.REACT_APP_API_PORT && process.env.REACT_APP_API_PORT.length) {
        envPath.push(':' + process.env.REACT_APP_API_PORT)
    }
    if (process.env.REACT_APP_API_PATH && process.env.REACT_APP_API_PATH.length) {
        envPath.push(process.env.REACT_APP_API_PATH)
    }
    envPath.push(path)
    return envPath.join('')
}

const request = async (url, options = {}, expectedStatusCode = 200) => {
    url = (isRelativeUrl(url) || options?.forceRelative) && !options?.forceFull ? path(url) : url 
    if(options.query) url = url + '?' + new URLSearchParams(options.query).toString()
    if(options.body && typeof options.body !== 'string'){ options.body = JSON.stringify(options.body)}
   
    const response = await fetch(url, options);
    
    if (!response.ok) {
        let error
        try{
            const errorJson = await response.json();
            error = new Error(errorJson.message);
        }catch(err){
            error = new Error(`An unexpected error occurred. Please contact support. status: ${response.status}`);
        }
        error.status = response.status;
        throw error;
    }
    const data = await response.json();
    if (response.status !== expectedStatusCode) {
        throw data;
    }
    return data;
}




function isRelativeUrl(url) {
    // Regular expression to check for pattern like domain name
    const domainPattern = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

    try {
        new URL(url);
        // If no exception is thrown, this is a full URL with a hostname
        return false;
    } catch (_) {
        // If the URL constructor throws, check if it matches the domain pattern
        return !domainPattern.test(url);
    }
}


const api = {
    path,
    request,
    isRelativeUrl

}

export default api;
