import { useState } from "react";

export const EmojiImage = ({
    emojisList = [],
    ...rest
}) => {
    const [emojis, setEmojis] = useState([]);

    const addEmoji = (x, y) => {
      const angle = Math.floor((Math.random() * 180) - 90); // Random angle from -90 to 90
      const newEmoji = { 
        id: Math.random(), 
        style: { left: x, top: y, transform: `translate(-50%, -50%) rotate(${angle}deg)` },
        emoji: emojisList[Math.floor(Math.random() * emojisList.length)]
    };
      setEmojis(currentEmojis => [...currentEmojis, newEmoji]);
  
      setTimeout(() => {
        setEmojis(currentEmojis => currentEmojis.filter(emoji => emoji.id !== newEmoji.id));
      }, 1000);
    };
  
    const handleClick = (event) => {
      const bounds = event.target.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      addEmoji(x, y);
    };
  
    return (
      <div className="emoji-image-container" onClick={handleClick}>
        <img draggable={false} alt={rest.alt} {...rest} />
        {emojis.map(emoji => (
          <span key={emoji.id} className="emoji" style={emoji.style}>{emoji.emoji}</span>
        ))}
      </div>
    );
  };