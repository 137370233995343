import { TiledCards } from "components/ui";
import { Container } from "react-bootstrap"


const tileContents = [
    {
        title: "Decades of Experience",
        message: "With a history dating back to 1991, Intellisoft Development has a deep and rich experience in the IT sector, ensuring your projects benefit from decades of accumulated knowledge and expertise.",
        iconClass: "bi-hourglass-split"
    },
    {
        title: "Specialized Services",
        message: "Specialization in contract workforce services, software development, technical writing, and editing allows us to provide highly focused and expert solutions tailored to your specific needs.",
        iconClass: "bi-puzzle"
    },
    {
        title: "Expertise in Complex Applications",
        message: "We excel in developing complex web and mobile applications, ensuring that even the most intricate projects are handled with precision and skill.",
        iconClass: "bi-code-slash"
    },
    {
        title: "Dedicated Agile Talent",
        message: "Our team comprises highly skilled agile talent, adept at adapting to changing project requirements and delivering results efficiently.",
        iconClass: "bi-lightning-charge-fill"
    },
    {
        title: "Commitment to Quality",
        message: "The excellence of our individuals is our cornerstone. We are committed to delivering superior quality in every project, ensuring your satisfaction.",
        iconClass: "bi-award-fill"
    },
    {
        title: "Customized Solutions",
        message: "Understanding that each project is unique, we offer personalized solutions that align perfectly with your specific goals and challenges, ensuring that your project is not just completed, but excelled.",
        iconClass: "bi-gear-fill"
    }
];


export const ChooseStrip = () => {
  

    return (
        <Container className='choose-grid-container'>
          <h2 className='mb-5 text-center service-text'>Why choose Intellisoft Development?</h2>
          <TiledCards tiles={tileContents}/>
        </Container>
    )
}