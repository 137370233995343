import { useEffect, useRef, useState } from "react"
import { Button, Col, Modal, ModalBody, Row, Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { createJob } from "../store/job.action"
import { makeToast } from "actions/toast.actions"
import { useCronToEnglish } from "../hooks/job.hook"
import { Autocomplete } from "components/form"


function tryJSON(json) {
    try {
        const test = JSON.parse(json)
        if (typeof test === 'object' && !Array.isArray(test))
            return [true, 'Valid JSON.']
        throw Error('Input is not a JSON object.')
    } catch (error) {
        return [false, error.message]
    }
}

const initialJobForm = {
    action: null,
    job: '',
    category: 'None',
    interval: '',
    data: JSON.stringify({}, null, 3),
    skipImmediate: true
}


const jobTypes = [
    {
        action: 'every',
        title: 'Repeating Job',
        desc: <p>A Job that <strong>repeats</strong> at a set interval or time.</p>,
        icon: 'calendar-week'
    },
    {
        action: 'schedule',
        title: 'Scheduled Job',
        desc: <p>A Job that <strong>runs once</strong> at a set scheduled time.</p>,
        icon: 'calendar-day'
    },
    {
        action: 'now',
        title: 'Run Now',
        desc: <p>Run a job once, immediately.</p>,
        icon: 'lightning-fill'
    }
]

const JobTypeGridButton = ({ handleType, action }) => (
    <Button onClick={handleType(action.action)} variant="light" className="grid-btn d-flex flex-column align-items-center">
        <i className={`bi bi-${action.icon} h1`}></i>
        <h1>{action.title}</h1>
        {action.desc}
    </Button>
)

export const JobCreateModal = ({ overview, setOpen, setRerender }) => {

    const dispatch = useDispatch()
    const [dataValidation, setDataValidation] = useState([true, 'Valid JSON.'])
    const [jobForm, setJobForm] = useState(initialJobForm)
    const formRef = useRef()
    const dataRef = useRef({
        data: jobForm.data,
        validated: dataValidation[0]
    })
    const cronEnglish = useCronToEnglish(jobForm.interval)

    const [validated, setValidated] = useState(false);



    const handleSubmit = async (event) => {
        const form = formRef.current;
        if (form.checkValidity() && dataValidation[0]) {
            dispatch(createJob(jobForm.action, jobForm.job, jobForm.interval, JSON.parse(jobForm.data), jobForm.skipImmediate))
            .then(result => {
                dispatch(makeToast('Success', result.message, {type:'success'}));
            })
            .finally(_=>{
                setRerender(prev => prev + 1)
                setOpen(false)
            })
        }
        setValidated(true);
    };


    const handleType = (action) => (e) => {
        setJobForm({
            ...jobForm,
            action
        })
    }

    const setJobFormControl = (key) => (value) => {
        setJobForm({
            ...jobForm,
            [key]: value
        })
    }



    useEffect(() => {
        const tryJ = tryJSON(jobForm.data);
        dataRef.current = {
            data: jobForm.data,
            validated: tryJ[0]
        }
        setDataValidation(tryJ);
    }, [jobForm.data, setDataValidation])

    useEffect(() => {
        if (dataRef.current.validated) {
            setJobForm(prev => ({
                ...prev,
                data: JSON.stringify({
                    ...JSON.parse(dataRef.current.data),
                    category: jobForm.category
                }, null, 3)
            }))
        }
    }, [jobForm.category, setJobForm])


    return (

        <Modal
            show={true}
            onHide={_ => setOpen(false)}
            className="modal-jobs"
            size="lg"
            centered
        >
            <ModalBody>
                {
                    jobForm.action ?

                        <div>
                            <Form ref={formRef} noValidate validated={validated}>
                                <Button onClick={handleType(null)} variant="light" className="mb-3"><i className={`bi bi-caret-left-fill`}></i> Back</Button>
                                <Row className="mb-2">
                                    <Form.Group as={Col}>
                                        <Form.Label>Job</Form.Label>
                                        <Autocomplete
                                            inputProps={{required:true, placeholder:'Job to run'}}
                                            getItemValue={(job) => job._id}
                                            items={overview.jobs}
                                            value={jobForm.job}
                                            onChange={(e) => setJobFormControl('job')(e.target.value)}
                                            onSelect={(v) => setJobFormControl('job')(v)}
                                            renderItem={job => job.displayName }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Provide a job to run
                                        </Form.Control.Feedback>


                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Category</Form.Label>
                                        <Autocomplete
                                            getItemValue={(cat) => cat}
                                            items={overview.categories}
                                            value={jobForm.category}
                                            onChange={(e) => setJobFormControl('category')(e.target.value)}
                                            onSelect={(v) => setJobFormControl('category')(v)}
                                            renderItem={cat => cat}
                                        />
                                    </Form.Group>

                                </Row>
                                <Row>
                                    {
                                        jobForm.action === 'every' || jobForm.action === 'schedule' ?
                                            <Form.Group as={Col} className="mb-2">
                                                <Form.Label>{jobForm.action === 'every' ? 'Repeat Interval' : 'Schedule for single run'}</Form.Label>
                                                <Form.Control
                                                    required
                                                    action="text"
                                                    placeholder="Enter interval"
                                                    value={jobForm.interval}
                                                    onChange={(e) => setJobFormControl('interval')(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Provide a interval
                                                </Form.Control.Feedback>
                                                {
                                                    cronEnglish.result
                                                    ?
                                                    <Form.Text muted>
                                                        {cronEnglish.result}
                                                    </Form.Text>
                                                    :
                                                    <Form.Text muted>
                                                        Use cron format or human readable unit: "1 day", "20 minutes"
                                                    </Form.Text>
                                                }
                                            </Form.Group>
                                            :
                                            <></>
                                    }
                                    {
                                        jobForm.action === 'every'  ?
                                            <Form.Group as={Col} xs={3} className="d-flex flex-row-reverse justify-content-center align-items-center gap-2">
                                                <Form.Label className="mb-0">Skip Immediate Run</Form.Label>
                                                <Form.Check 
                                                    checked={jobForm.skipImmediate}
                                                    onChange={_ => setJobFormControl('skipImmediate')(!jobForm.skipImmediate)}
                                                />
                                               
                                            </Form.Group>
                                            :
                                            <></>
                                    }
                                </Row>
                            </Form>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        value={jobForm.data}
                                        onChange={(e) => setJobFormControl('data')(e.target.value)}
                                        isValid={dataValidation[0]}
                                        isInvalid={!dataValidation[0]}
                                    />
                                    <Form.Control.Feedback type={'invalid'}>
                                        {dataValidation[1]}
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type={'valid'}>
                                        Valid JSON.
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} className="d-flex justify-content-end">
                                    <Button onClick={handleSubmit} type="click" variant="outline-primary">Create Job</Button>
                                </Form.Group>

                            </Row>

                        </div>
                        :
                        <div>
                            <div className="create-grid">
                                {
                                    jobTypes.map((action, i) => <JobTypeGridButton key={i} action={action} handleType={handleType} />)
                                }
                            </div>
                        </div>
                }
            </ModalBody>
        </Modal>

    )
}


export const JobCreate = ({ overview, setRerender }) => {

    const [open, setOpen] = useState(false)


    return (
        <>
            <div className="mb-3 mt-3" >
                <Button onClick={_ => setOpen(true)} variant={`${open ? '' : 'outline-'}primary`}> <i className={`bi bi-plus`}></i> Create</Button>
            </div>

            {
                open && <JobCreateModal setRerender={setRerender} overview={overview} setOpen={setOpen} />
            }

        </>
    )
}