import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Badge, Card } from 'react-bootstrap';



export const SteppedCards = ({
  steps = [],
  stepAmount = 40
}) => {
  const [linePaths, setLinePaths] = useState('');
  const [circles, setCircles] = useState([]);
  const [cardsFinishedAnimation, setCardsFinishedAnimation] = useState(false);
  const cardRefs = useRef([]);
  const containerRef = useRef(null);
  const [hoveredCard, setHoveredCard] = useState(null); // New state to track hovered card


  const calculateArcs = useCallback(() => {
    const containerRect = containerRef.current.getBoundingClientRect();
    let pathData = [];
    let circleData = [];
    cardRefs.current.forEach((card, i, arr) => {
      if (card && i < arr.length - 1 && arr[i + 1]) {
        const startRect = card.getBoundingClientRect();
        const endRect = arr[i + 1].getBoundingClientRect();

        // Starting point at the center of the current card
        const startX = startRect.left + startRect.width / 2 - containerRect.left;
        const startY = startRect.top - containerRect.top - 6;

        // Ending point at the center of the next card
        const endX = endRect.left + endRect.width / 2 - containerRect.left;
        const endY = endRect.top - containerRect.top - 6;


        const d = `M${startX},${startY} ${endX},${endY}`;
        // Circle at the start of the line
        circleData.push({ cx: startX, cy: startY, r: 12 }); // Radius of the circle

        // Circle at the end of the line (only add for the last line)
        if (i === arr.length - 2) {
          circleData.push({ cx: endX, cy: endY, r: 12 }); // Radius of the circle
        }

        pathData.push(d);
      }
    });

    setLinePaths(pathData.join(' '));
    setCircles(circleData);
  });

  useEffect(() => {
    // Set the initial height of the container to the height of the first card
    if (cardRefs.current[0]) {
      const firstCardHeight = cardRefs.current[0].getBoundingClientRect().height;
      containerRef.current.style.height = `${firstCardHeight * steps.length}px`;
    }
    // Start the animation after a short delay
    setTimeout(() => {
      cardRefs.current.forEach((card, index) => {
        card.style.transform = `translateY(${index * stepAmount}px)`; // Adjust this value to match the step difference
      });
      // Set the final height of the container after the cards have animated into place
      containerRef.current.style.height = '';
      setTimeout(() => {
        calculateArcs();
        setCardsFinishedAnimation(true)
      }, 500)
    }, 50); // This delay ensures the initial height is set before starting the animation

    // Re-calculate arcs on every resize
    const handleResize = () => calculateArcs();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [cardRefs.current, setCardsFinishedAnimation]);

  useEffect(()=>{
    if(cardsFinishedAnimation){
      cardRefs.current.forEach((card, index) => {
        const baseTransform = `translateY(${index * stepAmount}px)`; // Base transform for step effect
        const hoverTransform = hoveredCard === index ? 'rotate(-3deg)' : ''; // Rotate transform for hover effect
        card.style.transform = `${baseTransform} ${hoverTransform}`; // Adjust this value to match the step difference
      });
    }
  },[hoveredCard, cardRefs.current, cardsFinishedAnimation])

  return (
    
    <div className="stepped-cards-container" style={{ '--step-amount': stepAmount+'px' }} ref={containerRef}>
      <div className="cards-row">
        {steps.map((step, i) => (
          <Card
            key={`step-${i}`}
            ref={el => cardRefs.current[i] = el} // Assign refs for cards
            className={`step-card`}
            onMouseEnter={() => setHoveredCard(i)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <Card.Body>
              {step.step && <Badge className='mb-3' bg='primary'>{step.step}</Badge>}
              {step.title && <Card.Title>{step.title}</Card.Title>}
              {step.message && <Card.Text>{step.message}</Card.Text>}
              {step.body ? step.body : ''}
            </Card.Body>
          </Card>
        ))}
      </div>
      <svg className={`connecting-lines ${cardsFinishedAnimation ? 'opacity-100' : 'opacity-0'}`} xmlns="http://www.w3.org/2000/svg" style={{ zIndex: 1 }}>
        <path d={linePaths} stroke="#ede7e2" strokeWidth="2" fill="none" />
      </svg>
      <svg className={`connecting-circles ${cardsFinishedAnimation ? 'opacity-100' : 'opacity-0'}`} xmlns="http://www.w3.org/2000/svg" style={{ zIndex: 3 }}>
        {circles.map((circle, index) => (
          <circle key={index} cx={circle.cx} cy={circle.cy} r={circle.r} fill="var(--bs-secondary)" />
        ))}
      </svg>
    </div>
  );
};

