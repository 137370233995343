import { combineReducers } from "redux";
import { authReducer } from "features/authentication";
import { usersReducer } from "features/users";
import toastReducer from "./toast.reducer";

const reducers = combineReducers({
    auth : authReducer,
    users : usersReducer,
    toast: toastReducer,
})

export default reducers