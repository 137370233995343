import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

/**
 * Autocomplete component for selecting items from a dropdown list as you type.
 *
 * @param {Array} items - Array of items to display in the dropdown.
 * @param {any} value - Controlled value of the input. If not provided component will be uncontrolled.
 * @param {Function} onChange - Callback for input change events.
 * @param {Function} onSelect - Callback for item selection.
 * @param {Function} sortItems - Function for sorting items.
 * @param {Function} getItemValue - Function to get the display value from an item.
 * @param {Function} renderItem - Function to render each item.
 * @param {Function} renderMenu - Function to render the dropdown menu.
 * @param {Object} menuStyle - Styles for the dropdown menu.
 * @param {Function} renderInput - Function to render the input field.
 * @param {Object} inputProps - Additional props for the input field.
 * @param {Object} wrapperProps - Props for the optional wrapper element.
 * @param {Object} wrapperStyle - Styles for the optional wrapper element.
 * @param {Function} onMenuVisibilityChange - Callback for dropdown visibility changes.
 */
export const Autocomplete = ({
    items,
    value: propValue,
    onChange,
    onSelect,
    sortItems,
    getItemValue,
    renderItem,
    renderMenu,
    menuStyle,
    renderInput,
    inputProps,
    wrapperProps,
    wrapperStyle,
    onMenuVisibilityChange,
}) => {
    // States for managing internal component behavior.
    const [internalValue, setInternalValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    
    // Refs for DOM elements to manage focus and layout.
    const wrapperRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const [inputWidth, setInputWidth] = useState(0);

    let filteredItems = undefined;

    // Determine if the component is controlled or uncontrolled.
    const isControlled = propValue !== undefined;

    // Use internal state for uncontrolled component, prop value for controlled.
    const value = isControlled ? propValue : internalValue;

    // Check if a wrapper should be used based on provided props.
    const hasWrapper = wrapperProps || wrapperStyle;

    // Effect for handling clicks outside the component to close the dropdown.
    useEffect(() => {
        const handleClickOutside = (event) => {
            const outsideInput = inputRef.current && !inputRef.current.contains(event.target);
            const outsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target);
            const shouldCloseDropdown = hasWrapper 
                ? wrapperRef.current && !wrapperRef.current.contains(event.target)
                : outsideInput && outsideDropdown;

            if (shouldCloseDropdown) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, inputRef, dropdownRef, hasWrapper]);

    // Effect for notifying about menu visibility changes.
    useEffect(() => {
        if (onMenuVisibilityChange) {
            onMenuVisibilityChange(isOpen);
        }
    }, [isOpen, onMenuVisibilityChange]);

    // Effect for adjusting the dropdown width based on the input field width.
    useEffect(() => {
        if (inputRef.current) {
            const width = inputRef.current.getBoundingClientRect().width;
            setInputWidth(width);
        }
    }, [inputRef]);

    // Handler for input changes.
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setIsOpen(true);

        if (isControlled) {
            onChange?.(e, newValue);
        } else {
            setInternalValue(newValue);
            onChange?.(e, newValue);
        }
    };

    // Handler for selecting an item from the dropdown.
    const handleSelect = (item) => {
        const itemValue = getItemValue(item);

        if (isControlled) {
            onSelect?.(itemValue, item);
        } else {
            setInternalValue(itemValue);
            onSelect?.(itemValue, item);
        }
        setIsOpen(false);
    };

    // Handler for keyboard navigation within the dropdown.
    const handleKeyDown = (e) => {
        if (!isOpen) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setHighlightedIndex((prevIndex) =>
                    prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
                break;
            case 'Enter':
                e.preventDefault();
                handleSelect(filteredItems[highlightedIndex]);
                break;
            default:
                break;
        }
    };

    // Handler for mouse hover over dropdown items.
    const handleMouseEnter = (index) => {
        setHighlightedIndex(index);
    };

    // Filter and optionally sort items based on the current input value.
     filteredItems = items
        .filter(item => {
            const itemValue = getItemValue(item).toLowerCase();
            const searchText = value.toLowerCase();
            return itemValue.startsWith(searchText);
        })
        .sort((a, b) => (sortItems ? sortItems(a, b, value) : 0));

    // Effect to adjust highlighted index when filteredItems changes
    useEffect(() => {
        if (highlightedIndex >= filteredItems.length) {
            setHighlightedIndex(Math.max(filteredItems.length - 1, 0));
        }
    }, [filteredItems, highlightedIndex]);

    // Default render function for the dropdown menu.
    const defaultRenderMenu = (items, value, styles) => (
        <div style={{ ...menuStyle, ...styles }}>
            {items}
        </div>
    );

    // Default render function for the input field.
    const defaultRenderInput = (props) => (
        <Form.Control {...props} />
    );

    // Render function for the dropdown.
    const renderDropdown = () => {
        if (!isOpen || !filteredItems.length) return null;

        const dropdownStyle = {
            position: 'absolute',
            width: `${inputWidth}px`,
            zIndex: 1000,
            border: 'var(--bs-border-width) solid var(--bs-border-color)',
            ...menuStyle
        };

        const menuItems = filteredItems.map((item, index) => {
            const isHighlighted = index === highlightedIndex;
            return (
                <div
                    key={index}
                    onClick={() => handleSelect(item)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    style={{ background: isHighlighted ? 'var(--bs-secondary-bg-subtle)' : 'var(--bs-body-bg)' }}
                >
                    {renderItem(item, isHighlighted)}
                </div>
            );
        });

        return (
            <div ref={dropdownRef} style={{ position: 'absolute', zIndex: 1000, ...dropdownStyle }}>
                {renderMenu ? renderMenu(menuItems, value) : defaultRenderMenu(menuItems, value)}
            </div>
        );
    };
    
    // Render children elements (input field and dropdown).
    const renderChildren = () => (
        <>
             {renderInput
                ? renderInput({ ref: inputRef, value, onChange: handleInputChange, onKeyDown: handleKeyDown, ...inputProps })
                : defaultRenderInput({ ref: inputRef, value, onChange: handleInputChange, onKeyDown: handleKeyDown, ...inputProps })}
            {renderDropdown()}
        </>
    );

    // Conditional rendering based on whether a wrapper is used.
    return hasWrapper ? (
        <div {...wrapperProps} style={{ position: 'relative', ...wrapperStyle }} ref={wrapperRef}>
            {renderChildren()}
        </div>
    ) : (
        renderChildren()
    );
};

