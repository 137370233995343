import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthorized } from '../hooks/authorized.hook'
import { Loading } from 'pages/Loading'


export const PrivateRoute = ({ roles, children }) => {
    const auth = useSelector(state => state.auth)
    const isAuthorized = useAuthorized(roles)

    let location = useLocation()

    if(auth.isLoading){
        return <Loading/>
    }

    if(!auth.isAuthenticated){
        return <Navigate to="/login" state={{ from: location }} />;
    }

    if(!isAuthorized){
        return <Navigate to="/" state={{ from: location }} />;
    }

    return  children;

}