import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { UsersRoles } from './UsersRoles';
import { useSelector } from 'react-redux';
import { useSearchUsers } from '../hooks/search.hook';
import { UsersList } from './UsersList';
import { Pagination, Load } from 'components/ui';
import { UsersSearchInput } from '.';


export function UsersSearch() {

    const users = useSelector(state => state.users)

    const {
        roles,
        onInput,
        searchTerm,
        pagination,
        onPageInput,
        searchRoles,
        onRoleAdd,
        onRoleRemove
    } = useSearchUsers();

    return (
        <>
            <Form>
                <UsersSearchInput 
                onInput={onInput} 
                value={searchTerm} 
                onRoleAdd={onRoleAdd} 
                onRoleRemove={onRoleRemove} 
                roles={roles} />
                <UsersRoles selectedRoles={searchRoles} onRoleAdd={onRoleAdd} onRoleRemove={onRoleRemove} roles={roles} />
            </Form>
            {
                users.isLoading || users.rolesIsLoading ?
                    <>
                        <Load className='mt-5'/>
                    </>
                    :
                    <>
                        <UsersList />
                        <Pagination pagination={pagination} setPage={onPageInput} />
                    </>
            }
        </>

    )
}