

import { useIntersectionObserver } from '@uidotdev/usehooks';
import canadaOutline from 'assets/images/CanadaOutlineDark.png'
import { EmojiImage } from 'components/ui';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';


export const CanadianStrip = () => {

    const [hasIntersected, setHasIntersected] = useState(false)
    const [ref, entry] = useIntersectionObserver({
        threshold: [.3, 1], //30% of the content is show load or 100% (page reloads already scrolled down)
        root: null,
        rootMargin: "0px",
    });

    useEffect(() => {
        if (entry?.isIntersecting) {
            setHasIntersected(true)
        }
    }, [entry])


    return (
        <Container>
            <h2 className='text-light mb-2 text-center service-text'>Proudly coded in Canada 🍁</h2>
            <div ref={ref} className={`d-flex flex-column justify-content-center align-items-center proudly-canadian ${hasIntersected ? 'intersecting' : ''}`}>
                <EmojiImage emojisList={['❤️', '🍁']} width='250px' className='my-4' src={canadaOutline} alt='An outline of Canada' />
                <p className='text-light text-center mx-3'>
                At Intellisoft Development, <strong>our operations and development are exclusively conducted in Canada.</strong> As an integral player in the dynamic Canadian tech scene, we pride ourselves on delivering a distinctly Canadian fusion of quality, innovation, and reliability to every project. Let's innovate together, eh?
                </p>
            </div>
        </Container>
    )
}
