import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import { UserListItem } from './UserListItem'


export function UsersList() {

    //Get users to list
    const users = useSelector(state => state.users.users)

    return (
        <div>
            <ListGroup className='mb-3 animate__animated animate__fadeIn'>
                {
                    users.map(user => <UserListItem key={user.id} user={user} />)
                }
            </ListGroup>
        </div>
    )
}
