import { authRequest } from "features/authentication";

/** Types */
export const getJobs = ({
    job, 
    state, 
    skip, 
    limit , 
    query, 
    property, 
    isObjectId
}) => async (dispatch, getState) => {
        const data = await dispatch(authRequest('/jobs',  {
            method:'GET',
            query: { 
                job, 
                state, 
                skip, 
                limit, 
                query, 
                property, 
                
            },
            errorToast: true
        }));
        //Part to edit
        return data;
};

export const createJob = ( action, name, interval, data, skipImmediate ) => async (dispatch, getState) => {
    const result = await dispatch(authRequest(`/jobs`,  {
        method:'POST',
        body:{
            action, name, interval, data, skipImmediate
        },
        errorToast: true
    }));
    //Part to edit
    return result;
};

export const getJobHistory = ( jobId ) => async (dispatch, getState) => {
        const data = await dispatch(authRequest(`/jobs/${jobId}/history`,  {
            method:'GET',
            errorToast: true
        }));
        //Part to edit
        return data;
};

export const getJobOverview = ( ) => async (dispatch, getState) => {
    const data = await dispatch(authRequest(`/jobs/overview`,  {
        method:'GET',
        errorToast: true
    }));
    //Part to edit
    return data;
};

export const deleteJobs = (jobs)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs',  {
        method:'DELETE',
        body: {
            jobs
        },
        errorToast: true
    }));
    //Part to edit
    return result;
}


export const cancelJobs = (jobs)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs/cancel',  {
        method:'POST',
        body: {
            jobs
        },
        errorToast: true
    }));
    //Part to edit
    return result;
}

export const reQueueJobs = (jobs)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs/requeue',  {
        method:'POST',
        body: {
            jobs
        },
        errorToast: true
    }));
    //Part to edit
    return result;
}

export const pauseJobs = (jobs)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs/pause',  {
        method:'POST',
        body: {
            jobs
        },
        errorToast: true
    }));
    //Part to edit
    return result;
}


export const playJobs = (jobs)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs/play',  {
        method:'POST',
        body: {
            jobs
        },
        errorToast: true
    }));
    //Part to edit
    return result;
}


export const cronToEnglish = (expression)  => async (dispatch, getState) => {
    const result = await dispatch(authRequest('/jobs/cron',  {
        method:'GET',
        query: { expression },
        errorToast: false
    }));
    return result;
}
