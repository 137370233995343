import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';


export const ScrollRange = ({ contentRef, ...rest }) => {
    const [scrollValue, setScrollValue] = useState(0);
    const rangeRef = useRef(null)

    const updateScrollValue = () => {
        if (contentRef.current) {
            const maxScrollLeft = contentRef.current.scrollWidth - contentRef.current.clientWidth;
            const newScrollValue = (contentRef.current.scrollLeft / maxScrollLeft) * 100;
            setScrollValue(newScrollValue);
        }
    };

    useEffect(() => {
        // Add scroll event listener to the content div
        const div = contentRef.current;
        div.addEventListener('scroll', updateScrollValue);

        return () => {
            // Remove scroll event listener
            div.removeEventListener('scroll', updateScrollValue);
        };
    }, [contentRef]);

    const handleRangeChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setScrollValue(newValue);

        if (contentRef.current) {
            const newScrollLeft = (contentRef.current.scrollWidth - contentRef.current.clientWidth) * (newValue / 100);
            contentRef.current.scrollLeft = newScrollLeft;
        }
    };

    return (
        <Form.Range
            style={{'--thumb-percentage': `${scrollValue}%`}}
            ref={rangeRef}
            value={scrollValue}
            onChange={handleRangeChange}
            min={0}
            max={100}
            step={1}
            {...rest}
        />
    );
};