import { useIntersectionObserver } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export const TiledCards = ({tiles}) => {
    return (
        <div className="tiled-card-container">
            {tiles.map((tile, i) => <TiledCard key={i} tile={tile} />)}
        </div>
    );
};

export const TiledCard = ({ tile }) => {
    const [hasIntersected, setHasIntersected] = useState(false)
    const [ref, entry] = useIntersectionObserver({
        threshold: [.3, 1], //30% of the content is show load or 100% (page reloads already scrolled down)
        root: null,
        rootMargin: "0px",
    });

    useEffect(()=>{
        if(entry?.isIntersecting){
            setHasIntersected(true)
        }
    }, [entry])

    

    return (
        <div ref={ref} key={tile.id} className={`grid-item ${hasIntersected ? 'intersecting' : ''}`}>
            <h1><i className={`bi ${tile.iconClass} text-secondary`}></i></h1>
            <h2 className="tile-title text-primary">{tile.title}</h2>
            <p className="tile-description">{tile.message}</p>
        </div>
    )
}