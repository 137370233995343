import { useAuthorized } from "../hooks/authorized.hook"

export const Authorized = ({ 
    roles, 
    replace = <></>, 
    children
}) => {
    const isAuthorized = useAuthorized(roles)
    if(isAuthorized) return children
    return replace
}