import { useDebounce } from "@uidotdev/usehooks";
import { 
    addUsersSearchRole, 
    clearUser, 
    getRoles, 
    getUserRoles, 
    getUsers, 
    isLoading, 
    removeUsersSearchRole, 
    setUsersSearchPage, 
    setUsersSearchTerm 
} from "features/users/store/users.action";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USERS_SEARCH_PAGE, USERS_SEARCH_TERM } from "../store/users.reducer";

export const useSearchUsers = () => {
    
    const dispatch = useDispatch()

    
    const pagination = useSelector(state => state.users.pagination)
    const searchTerm = useSelector(state => state.users.searchTerm)
    const page = useSelector(state => state.users.page)
    
    const roles = useSelector(state => state.users.roles)
    const searchRoles = useSelector(state => state.users.searchRoles)

    const selected = useSelector(state => state.users.selected)
    const selectedRoles = useSelector(state => state.users.selectedRoles)

    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    

    function onInput(event){
        dispatch(setUsersSearchTerm(event.target.value))
    }

    function onPageInput(value){
        dispatch(setUsersSearchPage(value))
    }

    function onRoleAdd(value){
        dispatch(addUsersSearchRole(value))
    }

    function onRoleRemove(value){
        dispatch(removeUsersSearchRole(value))
    }

    useEffect(()=>{
        dispatch(getRoles())
    },[dispatch])
   

    useEffect(() => {
        dispatch(getUsers(debouncedSearchTerm, searchRoles, page, 10))
    }, [
        debouncedSearchTerm, 
        searchRoles,
        dispatch,
        page
    ])

    useEffect(()=>{
        dispatch(isLoading())
    },[searchTerm, dispatch])

    return {
        onRoleRemove,
        onPageInput,
        searchRoles,
        pagination,
        searchTerm,
        onRoleAdd,
        onInput,
        roles
    }

}