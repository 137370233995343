import React from 'react';
import { Container, Row, Col, Button, Form, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/idi-site-icon-white.png'

export const Footer = ({
    bg = 'primary',
    text = 'light'
}) => {

    const navigate = useNavigate()


    return (
        <footer className={`footer bg-${bg} text-${text} pt-4`}>
        <Container fluid>
          <Row className={`pb-3`}>
            <Col xs={12} md={4} className={`mb-1`}>
              <h4 className={`text-${text}`}><img width='50px' src={logo} alt='Intellisoft Logo'/> Intellisoft Development</h4>
              <p>Intellisoft Development Inc. has been providing Information Technology services since 1991, specializing in contract workforce services, software development, technical writing, and editing. Let us help you with your next project.</p>
            </Col>
            <Col xs={12} md={{span:4, offset:4}} className={`mb-3`}  >
              <h5 className={`text-${text}`}>Quick Links</h5>
              <ul className={`list-unstyled`}>
                <li><a href="/" className={`text-${text}`}>Home</a></li>
                <li><a href="/#choose-strip" className={`text-${text}`}>Why Intellisoft?</a></li>
                <li><a href="/contact" className={`text-${text}`}>Contact Us</a></li>
                {/* Add more links as needed */}
              </ul>
            </Col>
            {/* <Col xs={12} md={4} className={`mb-3`}>
              <h5 className={`text-${text}`}>Newsletter</h5>
              <Form>
                <Form.Group>
                  <Form.Control type="email" placeholder="Enter email" className={`mb-2`} />
                  <Button variant="outline-light" type="submit">Subscribe</Button>
                </Form.Group>
              </Form>
            </Col> */}
          </Row>
          <Row>
            <Col className={`rights-reserved text-center py-2`}>
              <span>© {new Date().getFullYear()} Intellisoft Development. All rights reserved.</span>
            </Col>
          </Row>
        </Container>
      </footer>
    );
};

