const { useSelector } = require("react-redux");

function isAuthorized(routeRoles, userRoles){
    let every = undefined;
    let any = undefined;
    if (typeof routeRoles === 'string') {
        any = [routeRoles];
    } else if (Array.isArray(routeRoles)) {
        any = routeRoles;
    } else if (typeof routeRoles === 'object') {
        any = routeRoles.any;
        every = routeRoles.every;
    }
    

    let satisfyEveryCondition = true;
    let satisfyAnyCondition = true;

    if (every) {
        satisfyEveryCondition = every.every(val => userRoles.includes(val));
    }

    if (any) {
        satisfyAnyCondition = any.some(val => userRoles.includes(val));
    }

    if ((any && every && (satisfyEveryCondition || satisfyAnyCondition)) ||
        (any && !every && satisfyAnyCondition) ||
        (!any && every && satisfyEveryCondition) ||
        (!any && !every)) {
        return true;
    }

    return false

}

export const useAuthorized = (roles) => {
    const auth = useSelector(state => state.auth)
    return isAuthorized(roles, auth.roles) && auth.isAuthenticated
}