export const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'; // Return 'N/A' if the timestamp is null or undefined

    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', { // Using 'en-US' for example, adjust as needed
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric', // 'numeric' instead of '2-digit' for no leading zeros
        minute: '2-digit',
        second: '2-digit',
        hour12: true // 12-hour format with AM/PM
    }).format(date);
};

export function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
        seconds == 60 ?
        (minutes+1) + "m 00s" :
        minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s"
      );
  }
  