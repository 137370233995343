

export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_LOADED = 'AUTH_USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const initial = {
    token:localStorage.getItem('token'),
    isAuthenticated:null,
    isLoading:true,
    user:null,
    roles:[],
    refresh:localStorage.getItem('refresh'),
    loginFail:false,
}


export function authReducer(state = initial, action) {
    switch(action.type){
        case AUTH_USER_LOADING:
            return {
            ...state,
            isLoading:true,
            loginFail:false,
        };
        case AUTH_USER_LOADED:
            return {
            ...state,
            isAuthenticated:true,
            isLoading:false,
            user: action.payload.user,
            roles: action.payload.roles,
            loginFail:false,
        };
        case AUTH_LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token)
            if(action.payload.refresh){
                localStorage.setItem('refresh', action.payload.refresh)
            }
            return {
            ...state,
            ...action.payload,
            isAuthenticated:true,
            isLoading:false,
            loginFail:false,
        };
        case AUTH_ERROR:
            localStorage.removeItem('token')
            return {
                ...state,
                token:null,
                user:null,
                roles:[],
                isAuthenticated:false,
                isLoading:false,
                loginFail:false,
            };
        case AUTH_LOGIN_FAIL:
            localStorage.removeItem('token')
            return {
                ...state,
                token:null,
                user:null,
                isAuthenticated:false,
                isLoading:false,
                roles:[],
                loginFail:true,
            };
            case AUTH_LOGOUT_SUCCESS:
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                return {
                    ...state,
                    token:null,
                    user:null,
                    isAuthenticated:false,
                    isLoading:false,
                    refresh:null,
                    roles:[],
                    loginFail:false,
                };
    
        default:
            return state;
    }

}