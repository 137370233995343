import { useState } from "react"
import { Alert, Badge, Button, ListGroup } from "react-bootstrap"
import { formatDate, millisToMinutesAndSeconds } from "util/date.util"



export const JobHistoryList = ({ history }) => {

    const [view, setView] = useState({
        column: null,
        history: null
    });



    const handleSetView = (column, history) => (e) => {
        if (column === view.column)
            setView({ column: null, history: null });
        else
            setView({ column, history });
    }

    return (
        <>

            {
                view.history && view.column && <div>
                    <Button onClick={handleSetView(view.column, view.history)} variant="light" className="mb-3"><i className={`bi bi-caret-left-fill`}></i>  Back</Button>
                    <h5>Viewing history record: <span className="text-muted" >{view.history._id}</span></h5>
                    </div>
            }
            <ListGroup>
                {
                    (view.history ? [view.history] : history).map(hist => (
                        <ListGroup.Item key={hist._id} className="px-4 d-flex flex-row align-items-center gap-4 history-item">
                            <div className="d-flex flex-column align-items-center history-box">
                                <i className={`bi bi-${hist.success ? 'check-circle-fill' : 'exclamation-circle-fill'} h3 mb-1 text-${hist.success ? 'success' : 'danger'}`}></i>
                                <Badge bg={hist.success ? 'success' : 'danger'} className="fw-light" pill>{hist.success ? 'Completed' : 'Failed'}</Badge>
                            </div>

                            <div className="d-flex flex-column">
                                <div><strong>Run At: </strong>{formatDate(hist.runAt)}</div>
                                <div><strong>Ended At: </strong>{formatDate(hist.endedAt)}</div>
                            </div>

                            <div className="d-flex flex-column">
                                <div><strong>Job time: </strong>{millisToMinutesAndSeconds(hist.jobTime)}</div>
                                <div><strong>Job ID: </strong>{hist.jobId}</div>
                            </div>

                            <div className="d-flex gap-3 ms-auto align-self-start">
                                <Button onClick={handleSetView('raw', hist)} variant={view.column === 'raw' ? 'dark' : 'light'}>
                                    <i className={`bi bi-braces`}></i> {view.column === 'raw' ? 'Close Raw' : 'View Raw'}
                                </Button>
                                <Button onClick={handleSetView('result', hist)} variant={view.column === 'result' ? 'primary' : 'outline-primary'}>
                                    <i className={`bi bi-braces`}></i> {view.column === 'result' ? 'Close Result' : 'View Result'}
                                </Button>

                            </div>



                        </ListGroup.Item>
                    ))
                }
            </ListGroup>

            {
                view.history &&
                <div className="mt-2">
                    {
                        view.column === 'raw' &&
                        <pre>{JSON.stringify(view.history, null, 3)}</pre>
                    }
                    {
                        view.column === 'result' &&
                        <Alert variant={view.history.success ? 'success' : 'danger'}>
                            <Alert.Heading as='h5'>Job {view.history.success ? 'Completed' : 'Failed'}</Alert.Heading>
                            <hr />
                            <p className='text-muted'>{view.history.success ? 'Job Result' : 'Fail Reason'}:</p>
                            <pre>{JSON.stringify(view.history.result, null, 3)}</pre>
                    
                        </Alert>
                    }
                </div>
            }
        </>
    )
}