import React from 'react'
import Main from '../layouts/Main';
import { Container } from 'react-bootstrap';
import { CanadianStrip, HeroStrip, ServiceStrip, ExperienceStrip } from 'features/home';
import { ChooseStrip } from 'features/home/components/ChooseStrip';




export default function Home() {

  return (
    <Main which="home">

      <div id='hero-strip' className='py-5'>
        <HeroStrip />
      </div>

      <div id='service-strip' className='py-5 bg-primary'>
        <ServiceStrip />
      </div>

      <div id='choose-strip' className='py-5 choose-grid'>
        <ChooseStrip />
      </div>
      
      <div id='experience-strip' className='pt-4 pb-6'>
        <ExperienceStrip />
      </div>

      <div id='canadian-strip' className='py-5 mt-4 bg-secondary'>
        <CanadianStrip />
      </div>

      

    </Main>
  )
}
