import React, { useState } from 'react'
import '../App.scss';

import Main from '../layouts/Main';
import { Alert, Container } from 'react-bootstrap';
import { JobList, JobSearch, useJobOverview, useJobSearch } from 'features/jobs';
import {Pagination } from 'components/ui';
import { JobCreate } from 'features/jobs/components/JobCreate';



export default function Jobs() {

  const {
    search,
    data,
    setRerender
  } = useJobSearch()

  const [overview, overviewLoading, overviewError] = useJobOverview()



  return (
    <Main which="jobs">
      <Container>
        <div>
          
          <h1 className='mt-5 text-center animate__animated animate__fadeIn'>Jobs</h1>
          <JobSearch search={search} overview={overview} />
          {
            overview && overview.failed ?
            <Alert dismissible variant='danger'>{overview.failed} job(s) have failed, <Alert.Link onClick={()=>search.setState('failed')}>filter by failed</Alert.Link> to see.</Alert>
            : (
              overview.completed ? 
                <Alert dismissible variant='success'>{overview.completed} job(s) completed successfully</Alert>
              : <></>
            )
          }
          <JobCreate overview={overview} setRerender={setRerender} />
          <JobList jobs={data.jobs} error={data.error} isLoading={data.isLoading} setRerender={setRerender} />
          <Pagination className='my-4' setPage={search.setPage} pagination={{
            totalPages: data.totalPages,
            page: search.page
          }} />


        </div>
      </Container>
    </Main>
  )
}