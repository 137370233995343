import { ScrollRange, SteppedCards } from 'components/ui'
import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'

const SERVICE_STEPS = [
    {
        step: "Step 1",
        title: "💬 Get in Touch",
        message: "Begin your journey by contacting us. Share your vision, goals, and challenges. Our team is ready to listen and understand your unique needs."
    },
    {
        step: "Step 2",
        title: "📝 Plan and Strategize",
        message: "Together, we'll develop a customized plan. Our experts will craft a strategy that aligns with your objectives, ensuring a clear path to success."
    },
    {
        step: "Step 3",
        title: "🔨 Development and Testing",
        message: "Watch your ideas come to life. Our skilled developers build your solution, rigorously testing every component to guarantee reliability and performance."
    },
    {
        step: "Step 4",
        title: "🚀 Launch and Support",
        message: "Launch your project with confidence. We provide ongoing support and updates, ensuring your software stays cutting-edge in an ever-evolving digital landscape."
    }
];

export const ServiceStrip = () => {
    const serviceWrapperRef = useRef()

    return (
        <Container>
            <h2 className='text-light mb-2 text-center service-text'>The Intellisoft Development Pathway</h2>
            <ScrollRange className='service-scroll' contentRef={serviceWrapperRef} />
            <div ref={serviceWrapperRef} className='service-wrapper hide-scrollbar'>
                <SteppedCards steps={SERVICE_STEPS} />
            </div>
        </Container>
    )
}