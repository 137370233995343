export const TOAST_MAKE = 'TOAST_MAKE';
export const TOAST_HIDE = 'TOAST_HIDE';

export const TOAST_ENTER = 'TOAST_ENTER';
export const TOAST_ENTERED = 'TOAST_ENTERED';

export const TOAST_SHOW = 'TOAST_SHOW';

export const TOAST_EXIT = 'TOAST_EXIT';
export const TOAST_REMOVE = 'TOAST_REMOVE';



const initialState = {

}

const defaultToast = {
    entering: false, //Set toast to enter scene
    entered: false, //Toast has finished entering
    shown: false, //Toast is entered and timer is set
    exited: false, //Toast is leaving scene

    type: 'info', //Toast bootstrap class
    title: 'Info', //Title for header of toast
    message: '', //Message for body of toast
    icon: 'bi-info-circle',
    closeButton: true, //Include a close button

    duration: 3000, //Show duration
    enterDuration: 500, //Enter animation duration
    exitDuration: 500
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {

        //Toast is triggered somewhere in the application and added to stack, Entering annimation starts
        case TOAST_MAKE: {
            return {
                ...state,
                [action.payload.id]: {
                    ...defaultToast,
                    ...action.payload,
                }
            };
        }

        //Toast Component detects new toast and
        case TOAST_ENTER: {
            let {
                [action.payload]: hideToast,
                ...rest
            } = state;
            return {
                ...rest,
                [action.payload]: {
                    ...hideToast,
                    entering: true
                }
            }
        }

        //Toast Component detects new toast and
        case TOAST_ENTERED: {
            let {
                [action.payload]: hideToast,
                ...rest
            } = state;
            return {
                ...rest,
                [action.payload]: {
                    ...hideToast,
                    entered: true,
                }
            }
        }
        case TOAST_HIDE: {

            let {
                [action.payload]: hideToast,
                ...rest
            } = state;

            return {
                ...rest,
                [action.payload]: {
                    ...hideToast,
                    shown: true
                }
            }
        }


        case TOAST_EXIT: {
            let {
                [action.payload]: hideToast,
                ...rest
            } = state;
            return {
                ...rest,
                [action.payload]: {
                    ...hideToast,
                    exited: true
                }
            }
        }

        case TOAST_REMOVE: {
            let {
                [action.payload]: removeToast,
                ...rest
            } = state;
            return rest;
        }
        default:
            return state;
    }
};

export default toastReducer;
