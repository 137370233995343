import { useCallback, useEffect, useState } from 'react';
import { Alert, Badge, ListGroup, Modal, Tab, Tabs } from 'react-bootstrap';
import { formatDate } from 'util/date.util';
import { JobListItem } from './JobList';
import { useViewJobHistory } from '../hooks/job.hook';
import { Load } from 'components/ui';
import { JobHistoryList } from './JobHistoryList';


export const JobView = ({
    job,
    setJob,
    handleClose = () => setJob(null),
}) => {

    const [history, historyLoading, historyError] = useViewJobHistory(job)

    return job && <Modal
        show={job}
        onHide={handleClose}
        size="xl"
        className='modal-jobs'
    >
        <Modal.Header closeButton>
            <h3>View {job.job.name}</h3>
        </Modal.Header>
        <Modal.Body>
            <Tabs
                defaultActiveKey="summary"
                id="view_job"
                className="mb-3"
                
            >
                <Tab eventKey="summary" title="Summary">
                    {
                        (
                            job.failed && <Alert variant='danger'>
                                <Alert.Heading as='h5'>Job Failed</Alert.Heading>
                                <hr />
                                <p className='text-muted'>Fail Reason:</p>
                                <pre>{JSON.stringify(job.job.failReason, null, 3)}</pre>
                            </Alert>
                        ) || (
                            job.completed && <Alert variant='success'>
                                <Alert.Heading as='h5'>Job Completed</Alert.Heading>
                                <hr />
                                <p className='text-muted'>Job Result:</p>
                                <pre>{JSON.stringify(job.job.result, null, 3)}</pre>
                            </Alert>
                        )
                    }
                   
                   <ListGroup>
                        <JobListItem job={job}/>
                   </ListGroup>

                </Tab>
                <Tab eventKey="history" title="History">
                    {
                        historyLoading ?
                        <Load />
                        :
                        (
                            historyError ?
                            <p>An error occurred getting history.</p>
                            :
                            <JobHistoryList history={history} />
                        )
                    }
                </Tab>
                <Tab eventKey="raw" title="Raw">
                    <pre>{JSON.stringify(job, null, 3)}</pre>
                </Tab>

            </Tabs>
        </Modal.Body>
    </Modal>
}