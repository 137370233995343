import Main from 'layouts/Main'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { useState } from 'react';
import { ProfileSecurity } from 'features/profile';





export default function Profile() {
    const [key, setKey] = useState('security');

    return (
        <Main which="profile">
            <Container className='mt-3'>
                <Tabs 
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                >
                <Tab eventKey="security" title="Security">
                    <ProfileSecurity/>
                </Tab>
                </Tabs>
            </Container>
        </Main>
    )
}
