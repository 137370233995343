import React from 'react'
import { Form } from 'react-bootstrap'


export function UsersSearchInput({
    onInput,
    value,
}) {


    return (
        <Form.Group className="users-search-form mt-4" controlId="search">
            <Form.Label><i className="bi bi-people-fill"></i> Search by Username</Form.Label>
            <Form.Control name="search" type='search' onInput={onInput} value={value} placeholder="Enter username" />
        </Form.Group>
    )
}
