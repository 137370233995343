import { Container } from "react-bootstrap"

export const TitleStrip = ({ }) => {
    return (
        <Container className="pt-4 text-center">
            <h1 className="animate__animated animate__fadeIn hero-text mb-3">
                Contact us.
            </h1>
            <p>
                Connect with Intellisoft Development Inc: Your journey towards innovative 
                solutions begins with a simple message. Share your thoughts, and 
                questions.
            </p>
        </Container>
    )
}