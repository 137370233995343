// Toast.js
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastEntered, hideToast, removeToast, toastEnter } from 'actions/toast.actions';
import { Alert } from 'react-bootstrap';

const SPACING_BETWEEN_TOASTS = 0;  // For example, 10 pixels. Adjust as needed.

const Toast = () => {
    const toasts = useSelector(state => state.toast);

    const dispatch = useDispatch();

    const [toastHeights, setToastHeights] = useState({});
    const toastRefs = useRef({});

    useEffect(() => {
        const newToastHeights = {};
        Object.keys(toasts).forEach(toastId => {
            const currentToast = toastRefs.current[toastId];
            if (currentToast) {
                const computedStyle = window.getComputedStyle(currentToast);
                const marginTop = parseFloat(computedStyle.marginTop);
                const marginBottom = parseFloat(computedStyle.marginBottom);
                newToastHeights[toastId] = currentToast.offsetHeight + marginTop + marginBottom;
            }
        });

        setToastHeights(newToastHeights);
    }, [toasts]);

    useEffect(() => {
        const timers = {};
        Object.entries(toasts).forEach(([id, toast]) => {


            if (toast.shown) {
                //If toast has been shown, Set timer based on duration of animation to hide toast then remove from state
                timers[toast.id] = setTimeout(() => {
                    dispatch(removeToast(id));
                }, toast.exitDuration);
            } else if (toast.entered) {
                //If toast has entered scene, Set timer based on duration to hide toast
                timers[toast.id] = setTimeout(() => {
                    dispatch(hideToast(id));
                }, toast.duration);
            } else if (toast.entering) {
                //If toast is set to entering, set animation to be complete after enter
                timers[toast.id] = setTimeout(() => {
                    dispatch(toastEntered(id));
                }, toast.enterDuration);
            } else if (!toast.entering) {
                //If toast has been created and placed out of scene, start enter animation
                dispatch(toastEnter(id))
            }
        });

        return () => {
            Object.values(timers).forEach(timer => clearTimeout(timer));
        };
    }, [toasts, dispatch]);


    const calculatePosition = (toastId) => {


        let right = toasts[toastId].entering ? 10 : -310;
        let bottom = SPACING_BETWEEN_TOASTS;
        if (toasts[toastId].shown) {
            bottom = -(toastHeights[toastId] + 10)
        } else {
            const toastIds = Object.keys(toasts);
            for (let i = 0; i < toastIds.length; i++) {
                if (toastIds[i] === toastId) break;
                bottom += (toastHeights[toastIds[i]] || 0) + SPACING_BETWEEN_TOASTS;
            }
        }

        return {
            bottom: bottom + 'px',
            right: right + 'px'
        };
    };


    return (
        <div className="toastContainer">
            {Object.entries(toasts).map(([id, { 
                type, 
                title,
                icon,
                message, 
                enterDuration, 
                exitDuration,
                closeButton
            }]) =>
                <Alert
                    key={id}
                    show={true}
                    variant={type}
                    dismissible={closeButton}
                    onClose={() => dispatch(hideToast(id))}
                    className={`gbc-toast`}
                    style={{ ...calculatePosition(id), transition:`bottom ${exitDuration}ms ease, right ${enterDuration}ms ease`}}
                    ref={el => toastRefs.current[id] = el}
                >
                    <Alert.Heading>
                        <i className={`bi ${icon} me-2`}></i>
                        <strong className="me-auto">{title}</strong>
                    </Alert.Heading>
                    <p>
                        {message}
                    </p>
                </Alert>
            )}
        </div>
    );
};

export default Toast;
