import { Load } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { sendContactForm } from '../store/contact.action'

const initialFields = {
    name: '',
    email: '',
    message: ''
}

const initialResponse = {
    isLoading: false,
    isSuccess: null,
    errorMessage: null
}

export const ContactStrip = () => {


    const [{ name, email, message }, setFields] = useState(initialFields)
    const [{ isLoading, isSuccess, errorMessage }, setResponse] = useState(initialResponse)
    const [showSuccess, setShowSuccess] = useState(false)

    const handleSubmit = event => {
        event.preventDefault();
        setResponse(response => ({ ...response, isLoading: true }))
        sendContactForm({ name, email, message })
            .then(data => {
                setResponse(response => ({
                    ...response,
                    isLoading: false,
                    isSuccess: true,
                    errorMessage: null
                }))
               // setFields(initialFields)
            })
            .catch(err => {
                console.log(err)
                setResponse(response => ({
                    ...response,
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: err?.message
                }))
            })
        // dispatch(makeToast('Message Sent', 'Your message has sent, please keep an eye in your email for a reply.', {type:'success', duration:5000}))
    }

    const setInputValue = key => event => {
        if(!isSuccess && !isLoading)
            setFields(fields => ({ ...fields, [key]: event?.target?.value }))
    }

    const handleBack = () =>{
        setFields(initialFields);
        setResponse(initialResponse);
    }

    useEffect(()=>{
        if(isSuccess){
            const t = setTimeout(_=>{
                setShowSuccess(true)
            }, 1000);
            return _ => clearTimeout(t)
        }else{
            const t = setTimeout(_=>{
                setShowSuccess(false)
            }, 1000);
            return _ => clearTimeout(t)
        }
    },[isSuccess])



    if(showSuccess){
        return (
            <Container className={`animate__animated${isSuccess ? ' animate__fadeIn' : ' animate__fadeOut' } text-center mt-3`}>
                <i className="h1 bi text-success bi-envelope-check"></i>

                
                <p className='h5 text-success'>Your message has been sent successfully.</p>
                <p>Please keep an eye on your inbox for a reply soon. We are excited to start a conversation.</p>
                <Button onClick={handleBack} variant='outline-primary px-2'>Back</Button>
            </Container>
        )
    }


    return (
        <Container>
            <Form onSubmit={handleSubmit} className={`contact-form animate__animated${isSuccess ? ' animate__fadeOut' : ' animate__fadeIn' }`}>
                <Row className='mb-2'>
                    <Col>
                        <Form.Group className='mb-2'>
                            <Form.Control maxLength={160} value={name} onInput={setInputValue('name')} placeholder='Full Name' required type='text' />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Control maxLength={256} value={email} onInput={setInputValue('email')} type='email' required placeholder='Email' />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Group>
                            <Form.Control value={message} onInput={setInputValue('message')} as='textarea' required rows={7} placeholder='Tell us a little more about your project' />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {errorMessage && <p className='text-danger'>{errorMessage}</p>}
                    </Col> 
                    <Col>
                        <div className='d-flex justify-content-end gap-2'>
                            {isLoading && <Load style={{ width: 50 }} />}
                            <Button disabled={isLoading} className='d-flex' type='submit'>Submit</Button>
                        </div>
                    </Col>
                </Row>

            </Form>

        </Container>
    )
}
