import { Autocomplete, GbcButton } from 'components/form';
import { useEffect, useState } from 'react';
import { Form, Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

export function JobSearch({
    search,
    overview
}) {
    const {
        job, setJob,
        query, setQuery,
        property, setProperty,
        isObjectId, setIsObjectId,
        state, setState,
        skip, setSkip,
        limit, setLimit
    } = search;

    const [advanced, setAdvanced] = useState(false);
    const [category, setCategory] = useState('');

    useEffect(()=>{
        if(category){
            setProperty('data.category')
            setQuery(category);
        }else{
            setProperty('')
            setQuery('');
        }
    }, [category, setQuery, setProperty])

    return (
        <Form className='animate__animated animate__fadeIn mb-3'>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Label>Job</Form.Label>
                    <Autocomplete
                        inputProps={{placeholder:'Filter by job'}}
                        getItemValue={(job) => job._id}
                        items={overview.jobs}
                        value={job}
                        onChange={(e) => setJob(e.target.value)}
                        onSelect={(v) => setJob(v)}
                        renderItem={ job => job.displayName }
                    />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>Category</Form.Label>
                    <Autocomplete 
                        getItemValue={(cat) => cat}
                        inputProps={{placeholder:'Filter by category'}}
                        items={overview.categories}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        onSelect={(v) => setCategory(v)}
                        renderItem={ cat => cat}
                    />
                </Form.Group>

            </Row>
            <Row className="mb-1">
                <Form.Group as={Col} >
                    <Form.Label>State</Form.Label>
                    <Form.Select
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    >
                        <option value="">Any</option>
                        <option value="scheduled">Scheduled</option>
                        <option value="queued">Queued</option>
                        <option value="running">Running</option>
                        <option value="completed">Completed</option>
                        <option value="failed">Failed</option>
                        <option value="repeating">Repeating</option>
                    </Form.Select>

                </Form.Group>
                <Form.Group as={Col} xs={1} className='d-flex flex-row-reverse' >
                    <Button onClick={_ => setAdvanced(!advanced)} className='mt-auto' variant={advanced ? 'dark' : 'light'}>Advanced</Button>
                </Form.Group>
            </Row>
            {
                advanced &&
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Property</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter property"
                            value={property}
                            onChange={(e) => setProperty(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Query</Form.Label>
                        <FormControl
                            placeholder="Enter query"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </Form.Group>
                </Row>
            }

            


        </Form>
    );
}
