
import React, { useCallback, useEffect, useState } from 'react';
import { ListGroup, Button, Badge, Spinner, Modal, Placeholder, FormCheck } from 'react-bootstrap';
import { ConfirmJobModal } from './JobModal';
import { cancelJobs, deleteJobs, pauseJobs, playJobs, reQueueJobs } from '../store/job.action';
import { useDispatch } from 'react-redux';
import { useJobStateSetter } from '../hooks/job.hook';
import { makeToast } from 'actions/toast.actions';
import { Pagination } from 'components/ui';
import { JobView } from './JobView';
import { formatDate } from 'util/date.util';

export const ACTIONS = {
    cancel: {
        condition: status => status.status === 'running',
        variant: 'secondary',
        icon: 'bi-stop-fill',
        method: cancelJobs,
        title: 'Cancel'
    },
    pause: {
        condition: status => status.status === 'running',
        variant: 'warning',
        icon: 'bi-pause-fill',
        method: pauseJobs,
        title: 'Pause'
    },
    play: {
        condition: status => status.status === 'paused',
        variant: 'success',
        icon: 'bi-play-fill',
        method: playJobs,
        title: 'Play'
    },
    delete: {
        condition: status => status.status !== 'running',
        variant: 'danger',
        icon: 'bi-trash-fill',
        method: deleteJobs,
        title: 'Delete'
    },
    requeue: {
        condition: status => true,
        variant: 'light',
        icon: 'bi-arrow-clockwise',
        method: reQueueJobs,
        title: 'ReQueue'
    },
    // view: {
    //     condition: status => true,
    //     variant: 'primary',
    //     icon: 'bi-eye-fill',
    //     method: () => { },
    //     title: 'View'
    // }


}

export const jobStatus = (job) => {
    // Logic to determine the job's current status
    if (job.running) {
        if (job.job.data && job.job.data.cancelled) {
            return { status: 'stopping', text: 'Stopping', variant: 'danger', icon: 'grow' }
        } else if (job.job.data && job.job.data.paused) {
            return { status: 'paused', text: 'Paused', variant: 'warning', icon: 'grow' };
        } else {
            return { status: 'running', text: 'Running', variant: 'success', icon: 'grow' };
        }
    } else if (job.failed) {
        return { status: 'failed', text: 'Failed', variant: 'danger', icon: 'exclamation-circle-fill' };
    } else if (job.completed) {
        return { status: 'completed', text: 'Completed', variant: 'success', icon: 'check-circle-fill' };
    } else if (job.scheduled) {
        return { status: 'scheduled', text: 'Scheduled', variant: 'info', icon: 'record-circle-fill' };
    }else {
        return { status: 'dormant', text: 'Dormant', variant: 'dark', icon: 'dash-circle-fill' };
    }
    // Add more conditions as necessary
};

export const JobList = ({ jobs, isLoading, error, setRerender }) => {

    const [multiList, setToMultiList] = useState([]);
    const [action, setAction] = useState(null);
    const [view, setView] = useState(null);
    const dispatch = useDispatch()

    const handleConfirm = useCallback(async () => {
        if (action) {
            const result = await dispatch(ACTIONS[action].method(multiList.map(j => j._id)));
            if (result) {
                dispatch(makeToast('Success', result.message, { type: 'success', icon: 'bi-check-circle-fill' }))
                setToMultiList([])
            }
        }
    }, [multiList, action, setToMultiList, dispatch])

    useEffect(() => {
        if (!multiList.length) {
            setAction(null)
        }
    }, [multiList])

    useEffect(() => {
        if (!action) {
            setRerender(prev => prev + 1);
        }
    }, [action])



    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error occurred, please try to refresh the page or contact support.</div>;



    return (
        <>
            <div className='mb-2 mt-4 d-flex'>
                <FormCheck
                    className='me-3'
                    type="checkbox"
                    label="Toggle All"
                    onChange={e => e.target.checked ? setToMultiList(jobs) : setToMultiList([])}
                />
                <Button disabled={!multiList.length || multiList.some(j => !j.running)} variant="dark" onClick={_ => setAction('cancel')} size="sm" className="me-2">
                    <i className="bi bi-stop-fill"></i> Abort
                </Button>

                <Button disabled={!multiList.length || !multiList.every(j => jobStatus(j).status === 'running')} variant="warning" onClick={_ => setAction('pause')} size="sm" className="me-2">
                    <i className="bi bi-pause-fill"></i> Pause
                </Button>

                <Button disabled={!multiList.length} variant="danger" onClick={_ => setAction('delete')} size="sm" className="me-2">
                    <i className="bi bi-trash-fill"></i> Delete
                </Button>
                <Button disabled={!multiList.length} variant="light" onClick={_ => setAction('requeue')} size="sm">
                    <i className="bi bi-arrow-clockwise"></i> Re-queue
                </Button>
            </div>

            <ListGroup>

                {
                    jobs
                        .map((job, index) => (
                            <JobListItem job={job} key={index} controls={{ action, setAction, setView, setToMultiList, multiList }} />
                        ))
                }




            </ListGroup>

            <ConfirmJobModal setView={setView} jobs={multiList} action={action} onClick={handleConfirm} setJobs={setToMultiList} title={`Are you sure you want to ${action} the following job(s)?`} />
            <JobView job={view} setJob={setView} />

        </>
    );
};


export const JobListItem = ({
    job,
    controls = {}
}) => {
    const { action, setView, setAction, setToMultiList, multiList } = controls;
    const isSelected = multiList && multiList.some(j => j._id === job._id);
    const handleSelect = () => setToMultiList([...multiList, job]);
    const handleDeSelect = () => setToMultiList(multiList.filter(j => j._id !== job._id))
    const handleClick = _ => {
        if (setToMultiList) {
            isSelected ? handleDeSelect() : handleSelect()
        }
    }

    const handleAction = (action) => (e) => {
        e.stopPropagation();
        if (setToMultiList) {
            setToMultiList([job]);
            setAction(action)
        }

    }

    const status = jobStatus(job);


    return (
        <ListGroup.Item onClick={handleClick} key={job._id} className="px-4 d-flex flex-row align-items-center gap-4">
            {
                setToMultiList &&
                <div>
                    <FormCheck
                        type="checkbox"
                        checked={isSelected}
                        onChange={isSelected ? handleDeSelect : handleSelect}
                    />
                </div>
            }
            <div className='d-flex flex-column align-items-center gap-2 status-box'>
                {status && (status.icon === 'grow') ? (
                    <Spinner animation="grow" variant={status.variant} />
                ) : (
                    <i className={`bi bi-${status.icon} h1 text-${status.variant}`}></i>
                )}
                <Badge bg={status.variant} className="mt-3" pill>{status.text}</Badge>
            </div>

            <div className='w-100'>
                <div className='d-flex'>
                    <div>
                        <p className='fw-bold mb-0'>Name: {job.job.name}</p>
                        <p className=''>Category: {(job.job.data && job.job.data.category) || 'None'}</p>
                    </div>


                    <div className="ms-auto text-end">

                        <div className="mb-2">
                            {
                                setToMultiList &&
                                Object.entries(ACTIONS)
                                    .filter(([name, action]) => action.condition(status))
                                    .map(([name, { variant, icon, title }]) => (
                                        <Button key={name} variant={variant} size="sm" onClick={handleAction(name)} className="me-2">
                                            <i className={`bi ${icon}`}></i> {title}
                                        </Button>
                                    ))
                            }
                            {
                                setView &&
                                <Button variant='primary' size="sm" onClick={e => { e.stopPropagation(); setView(job) }} className="me-2">
                                    <i className={`bi bi-eye-fill`}></i> View
                                </Button>
                            }
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-between">

                    <div>
                        <div><strong>Last Run At:</strong> {formatDate(job.job.lastRunAt)}</div>
                        <div className={status.status === 'completed' ? 'text-success fw-bold' : ''}><strong>Last Finished At:</strong> {formatDate(job.job.lastFinishedAt)}</div>


                    </div>
                    <div className="ms-4" >
                        <div><strong>Next Run At:</strong> {formatDate(job.job.nextRunAt)}</div>
                        <div><strong>Locked At:</strong> {job.job.lockedAt || 'N/A'}</div>



                    </div>
                    <div className="ms-4" >
                        <div className={status.status === 'failed' ? 'text-danger fw-bold' : ''}><strong>Last Failed At:</strong> {formatDate(job.job.failedAt)}</div>
                        <div><strong>Fail Count:</strong> {job.job.failCount || 'N/A'}</div>


                    </div>
                    <div className="ms-auto text-end">
                        <div>

                            {job.scheduled && <Badge bg="info" pill className="me-1">Scheduled</Badge>}
                            {job.queued && <Badge bg="primary" pill className="me-1">Queued</Badge>}
                            {job.repeating && <Badge bg="secondary" pill className="me-1">Repeating</Badge>}
                            {job.job.repeatInterval && <Badge bg="warning" pill className="me-1 text-dark">{job.job.repeatInterval}</Badge>}
                        </div>
                    </div>
                </div>
            </div>
        </ListGroup.Item>
    )
}



