import { Button } from 'react-bootstrap'

export function GbcButton({
    chevron = 'right',
    className = '',
    children,
    ...rest
    } = {}){
    return <Button {...{...rest, className:'gbc-btn ' + className}}>
        { chevron === 'left' ? <i className="bi bi-chevron-left font-emphasized"></i> : <></> }{children}{ chevron === 'right' ? <i className="bi bi-chevron-right font-emphasized"></i> : <></> }
    </Button>

}

