import React from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLogin } from '../hooks/login.hook'
import { GbcButton } from 'components/form'



export function LoginForm() {


    const auth = useSelector(state => state.auth)
    const {
        setUsername,
        setPassword,
        onLogin,
        setInput
    } = useLogin()



    return (
        <Form onSubmit={onLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control required type="username" name="username" onChange={e => setInput(e, setUsername)} placeholder="Enter Username" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control required type="password" name="password" onChange={e => setInput(e, setPassword)} placeholder="Enter Password" />
            </Form.Group>
            
            <GbcButton type="submit">
                Submit 
            </GbcButton>
            
            {
                auth.loginFail && <p className='mt-2 text-danger'>Wrong email or password</p>
            }
        </Form>
    )
}

