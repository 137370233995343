import React from 'react'
import { Spinner } from 'react-bootstrap'

export function Load({
  className = '',
  ...rest
}) {
  return (
    <div className={'d-flex justify-content-center align-items-center animate__animated animate__fadeIn ' + className}  {...rest}>
        <Spinner animation='grow' variant='primary' />
    </div>
  )
}
