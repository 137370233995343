import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from "react-router-dom";
import reducers from 'reducers';




const store = configureStore({reducer: reducers});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    
      <BrowserRouter basename={process.env['REACT_APP_BASEPATH']}>
      <App/>
      </BrowserRouter>
   
    </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
